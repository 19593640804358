"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
function Options(_a) {
    var name = _a.name, onChange = _a.onChange, value = _a.value, options = _a.options, disabled = _a.disabled;
    return (React.createElement("div", { className: "relative flex flex-row h-full z-0" }, options.map(function (_a, index) {
        var key = _a[0], text = _a[1], optionDisabled = _a[2];
        var isDisabled = disabled || optionDisabled;
        return (React.createElement("button", { disabled: isDisabled, key: "".concat(name, "_").concat(key), onClick: function () { return onChange(key); }, className: [
                index === 0 && "rounded-l",
                index > 0 && index < options.length - 1 && "border-l-0 ",
                index > 0 && options.length - 1 && "border-l-0",
                index === options.length - 1 && "rounded-r ",
                isDisabled && "text-gray-300 border-gray-300 cursor-not-allowed",
                !isDisabled && value !== key && "bg-white text-primary z-0",
                !isDisabled && value === key && "bg-primary border-primary text-white -m-0.5 border-r-2 border-l-2 border-t-2 border-b-2 z-10 rounded-l rounded-r",
                "font-semibold flex-1 text-center text-sm py-3",
                "relative border cursor-pointer"
            ].join(" ") }, text));
    })));
}
exports.default = Options;
