/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Profile extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Profile
         * @name GetCurrentUserProfileForEdit
         * @request GET:/api/services/app/Profile/GetCurrentUserProfileForEdit
         * @deprecated
         * @response `200` `AuthorizationUsersProfileCurrentUserProfileEdit` Success
         */
        this.GetCurrentUserProfileForEdit = (params = {}) => this.request({
            path: `/api/services/app/Profile/GetCurrentUserProfileForEdit`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Profile
         * @name UpdateCurrentUserProfile
         * @request PUT:/api/services/app/Profile/UpdateCurrentUserProfile
         * @deprecated
         * @response `200` `void` Success
         */
        this.UpdateCurrentUserProfile = (data, params = {}) => this.request({
            path: `/api/services/app/Profile/UpdateCurrentUserProfile`,
            method: "PUT",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Profile
         * @name ChangePassword
         * @request POST:/api/services/app/Profile/ChangePassword
         * @response `200` `void` Success
         */
        this.ChangePassword = (data, params = {}) => this.request({
            path: `/api/services/app/Profile/ChangePassword`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Profile
         * @name UpdateProfilePicture
         * @request PUT:/api/services/app/Profile/UpdateProfilePicture
         * @response `200` `string` Success
         */
        this.UpdateProfilePicture = (data, params = {}) => this.request({
            path: `/api/services/app/Profile/UpdateProfilePicture`,
            method: "PUT",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Profile
         * @name GetPasswordComplexitySetting
         * @request GET:/api/services/app/Profile/GetPasswordComplexitySetting
         * @response `200` `AuthorizationUsersProfileGetPasswordComplexitySettingOutput` Success
         */
        this.GetPasswordComplexitySetting = (params = {}) => this.request({
            path: `/api/services/app/Profile/GetPasswordComplexitySetting`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Profile
         * @name ChangeLanguage
         * @request POST:/api/services/app/Profile/ChangeLanguage
         * @response `200` `void` Success
         */
        this.ChangeLanguage = (data, params = {}) => this.request({
            path: `/api/services/app/Profile/ChangeLanguage`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
    }
}
