export function validateQuoteItem(item, index, values) {
  let errors = {}

  if (!item.length.value) {
    errors['length'] = `Item ${Number.parseInt(index, 10) + 1} length is required`
  }
  if (!item.width.value) {
    errors['width'] = `Item ${Number.parseInt(index, 10) + 1} width is required`
  }
  if (!item.height.value) {
    errors['height'] = `Item ${Number.parseInt(index, 10) + 1} height is required`
  }
  if (!item.weight.value) {
    errors['weight'] = `Item ${Number.parseInt(index, 10) + 1} weight is required`
  }
  if (!item.quantity) {
    errors['quantity'] = `Item ${Number.parseInt(index, 10) + 1} quantity is required`
  }
  if (!item.description) {
    errors['description'] = `Item ${Number.parseInt(index, 10) + 1} description is required`
  }

  // if (values.fromAddress.countryCode !== values.toAddress.countryCode) {
  //   if (!item.hsCode) {
  //     errors['quantity'] = `Item ${Number.parseInt(index, 10) + 1} HS Code is required`
  //   }
  //   if (!item.itemValue) {
  //     errors['quantity'] = `Item ${Number.parseInt(index, 10) + 1} cost is required`
  //   }
  //   if (!item.currency) {
  //     errors['quantity'] = `Item ${Number.parseInt(index, 10) + 1} currency is required`
  //   }
  //   if (!item.manufactureCountryCode) {
  //     errors['quantity'] = `Item ${Number.parseInt(index, 10) + 1} origin is required`
  //   }
  // }


  return Object.keys(errors).length > 0 ? errors : undefined;
}

export function validateQuoteItems(items, values) {
  for (let index in items) {
    let item = items[index]
    let errors = validateQuoteItem(item, index, values)
    if (errors) {
      return errors
    }
  }

  return undefined
}
