/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Payment extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Payment
         * @name CreateRemittanceAdvice
         * @request POST:/api/services/app/Payment/CreateRemittanceAdvice
         * @response `200` `FinancePaymentsCreateRemittanceAdviceOutput` Success
         */
        this.CreateRemittanceAdvice = (data, params = {}) => this.request({
            path: `/api/services/app/Payment/CreateRemittanceAdvice`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Payment
         * @name GetRemittanceAdvices
         * @request GET:/api/services/app/Payment/GetRemittanceAdvices
         * @response `200` `PagedResultFinancePaymentsRemittanceAdviceList` Success
         */
        this.GetRemittanceAdvices = (query, params = {}) => this.request({
            path: `/api/services/app/Payment/GetRemittanceAdvices`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Payment
         * @name DeleteRemittanceAdvice
         * @request DELETE:/api/services/app/Payment/DeleteRemittanceAdvice
         * @response `200` `void` Success
         */
        this.DeleteRemittanceAdvice = (query, params = {}) => this.request({
            path: `/api/services/app/Payment/DeleteRemittanceAdvice`,
            method: "DELETE",
            query: query,
            ...params,
        });
        /**
         * No description
         *
         * @tags Payment
         * @name CreatePayment
         * @request POST:/api/services/app/Payment/CreatePayment
         * @response `200` `void` Success
         */
        this.CreatePayment = (data, params = {}) => this.request({
            path: `/api/services/app/Payment/CreatePayment`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Payment
         * @name CreateCreditCardPayment
         * @request POST:/api/services/app/Payment/CreateCreditCardPayment
         * @response `200` `void` Success
         */
        this.CreateCreditCardPayment = (data, params = {}) => this.request({
            path: `/api/services/app/Payment/CreateCreditCardPayment`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Payment
         * @name AllocateCreditPayment
         * @request POST:/api/services/app/Payment/AllocateCreditPayment
         * @response `200` `void` Success
         */
        this.AllocateCreditPayment = (data, params = {}) => this.request({
            path: `/api/services/app/Payment/AllocateCreditPayment`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Payment
         * @name GetPayments
         * @request GET:/api/services/app/Payment/GetPayments
         * @response `200` `PagedResultFinancePaymentsPaymentList` Success
         */
        this.GetPayments = (query, params = {}) => this.request({
            path: `/api/services/app/Payment/GetPayments`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Payment
         * @name GetPaymentForEdit
         * @request GET:/api/services/app/Payment/GetPaymentForEdit
         * @response `200` `FinancePaymentsPaymentOutput` Success
         */
        this.GetPaymentForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/Payment/GetPaymentForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Payment
         * @name RefundCreditNotes
         * @request POST:/api/services/app/Payment/RefundCreditNotes
         * @response `200` `void` Success
         */
        this.RefundCreditNotes = (data, params = {}) => this.request({
            path: `/api/services/app/Payment/RefundCreditNotes`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Payment
         * @name GetRefunds
         * @request GET:/api/services/app/Payment/GetRefunds
         * @response `200` `PagedResultFinancePaymentsRefundList` Success
         */
        this.GetRefunds = (query, params = {}) => this.request({
            path: `/api/services/app/Payment/GetRefunds`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Payment
         * @name GetTransactionInfo
         * @request GET:/api/services/app/Payment/GetTransactionInfo
         * @response `200` `(FinancePaymentsPaymentTransactionOutput)[]` Success
         */
        this.GetTransactionInfo = (query, params = {}) => this.request({
            path: `/api/services/app/Payment/GetTransactionInfo`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    }
}
