import * as React from "react"
import useOnClickOutside from "@shipeedo/ui-library/hooks/useOnClickOutside"
import { QuoteContext } from "../../contexts/quote-context"

interface QuoteWrapperProps {
  noContainer?: boolean
  children: React.ReactNode
}

export default function QuoteWrapper({
  children,
  noContainer,
}: QuoteWrapperProps) {
  let wrapper = React.createRef<HTMLDivElement>()
  let quote = React.useContext(QuoteContext)

  useOnClickOutside(wrapper, () => {
    quote.setFocus(false)
  })

  let handleFocus = () => {
    quote.setFocus(true)
  }

  React.useEffect(() => {
    if (noContainer) {
      quote.setFocus(true)
    }

    wrapper?.current?.addEventListener("click", handleFocus)
    return () =>
      wrapper.current &&
      wrapper.current.removeEventListener("click", handleFocus)
  }, [])

  return (
    <>
      {noContainer ? (
        children
      ) : (
        <>
          <div
            className={[
              "bg-gray-900 transition-opacity duration-300 fixed z-20 inset-0 w-full h-full pointer-events-none",
              quote.hasFocus ? "opacity-75" : "opacity-0",
            ].join(" ")}
            style={{ zIndex: 1 }}
          />

          <div
            ref={wrapper}
            className="bg-white rounded shadow-xl w-full md:max-w-4xl mx-auto relative transition-all duration-300"
            style={{ zIndex: 1 }}
          >
            {children}
          </div>
        </>
      )}
    </>
  )
}
