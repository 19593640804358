import * as React from "react"
import { Formik, Field } from "formik"

import AddressSuburbSelector from "@shipeedo/ui-library/components/address/suburb-selector"
import { AddressType } from "../address/type"
import AddressOptions from "@shipeedo/ui-library/components/address/options"
import Items from "@shipeedo/ui-library/components/items/items"
import { ApiContext } from "@shipeedo/ui-library/contexts/api"
import ValidationErrors from "@shipeedo/ui-library/components/form/validation-errors"
import AddressCountrySelector from "@shipeedo/ui-library/components/address/country-selector"

import { QuoteContext } from "../../contexts/quote-context"
import { BookingContext } from "../../contexts/booking-context"
import { validateQuoteAddress } from "../../validate/quote/address"
import { validateQuoteItems } from "../../validate/quote/items"

interface QuoteWidgetProps {
  noContainer?: boolean
  onGetQuotes?: (payload: any) => void
}

export default function QuoteWidget(props: QuoteWidgetProps) {
  let quote = React.useContext(QuoteContext)
  let { endpoint } = React.useContext(ApiContext)
  let [errors, setErrors] = React.useState<any>([])
  let booking = React.useContext(BookingContext)
  const {
    fromCountryCode,
    fromSuburb,
    fromPostCode,
    toCountryCode,
    toSuburb,
    toPostCode,
    promoCode,
  } = quote.config || {}

  const handleValidate = (values) => {
    let result = {
      fromAddress: validateQuoteAddress(values.fromAddress, "pickup", values),
      toAddress: validateQuoteAddress(values.toAddress, "delivery", values),
      items: validateQuoteItems(values.items, values),
    }

    let newErrors = []
    for (let key in result) {
      let errorMessages = result[key]
        ? Object.keys(result[key]).map((k) => ({ message: result[key][k] }))
        : []
      newErrors = [...newErrors, ...errorMessages]
    }

    setErrors(newErrors)

    // hack to remove undefined properties - its nearly 1am. don't judge me
    result = JSON.parse(JSON.stringify(result))
    return result
  }

  const handleSubmitQuote = (values, { setSubmitting }) => {

    // if international, fix values.items
    if (values.fromAddress.countryCode.toLowerCase() !== values.toAddress.countryCode.toLowerCase()) {
      for (let item of values.items) {
        item.hsCode = '1'
        item.currency = 'AUD'
        item.itemValue = 1
      }
    }

    if (props.noContainer && booking) {
      booking.setShowQuoteForm(false)
      props.onGetQuotes(values)
      return
    }

    let form = document.createElement("form")
    form.method = "POST"
    form.action = `${endpoint}/bookings-guest/book-now`
    let data = document.createElement("input")
    data.setAttribute("name", "data")
    data.setAttribute(
      "value",
      JSON.stringify({ ...values, gclid: quote.gclid })
    )
    form.appendChild(data)
    document.body.appendChild(form)
    form.submit()
    document.body.removeChild(form)
    setSubmitting(false)
  }

  const handleCancel = () => {
    if (booking) {
      booking.setShowQuoteForm(false)
    }
  }

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{
        fromAddress: {
          isResidential: false,
          countryCode: fromCountryCode || "AU",
          postCode: fromPostCode,
          suburb: fromSuburb,
        },
        toAddress: {
          isResidential: false,
          countryCode: toCountryCode || "AU",
          postCode: toPostCode,
          suburb: toSuburb,
        },
        deliveryOptions: {
          ATL: "true",
        },
        pickupOptions: {},
        filter: {},
        promoCode,
        gclid: quote.gclid,
        ...quote.payload,
      }}
      validate={handleValidate}
      onSubmit={handleSubmitQuote}
    >
      {({ handleSubmit, values }) => {
        return (
          <div className={`px-2 md:px-6 py-4`}>
            <div className="grid grid-cols-6 gap-x-6 gap-y-2">
              <div className="col-span-6 border-b pb-3 md:border-b-0 md:pb-0 md:col-span-3">
                <div className="from mb-3">
                  {quote.tab === "international" && (
                    <div className="mb-2">
                      <label className="text-xs text-gray-500 block mb-1">
                        Pickup country
                      </label>
                      <div className="h-10">
                        <AddressCountrySelector property="fromAddress" />
                      </div>
                    </div>
                  )}
                  <div className="relative">
                    <div className="h-16 pb-6">
                      <AddressSuburbSelector
                        label="Pickup suburb"
                        property="fromAddress"
                        tabIndex={0}
                      />
                    </div>
                  </div>
                  {quote.hasFocus && (
                      <div className="flex flex-col mt-2">
                        <label className="text-xs text-gray-500 block mb-1 mt-1">Pickup address type</label>
                        <AddressType property="fromAddress" simple={true} />
                      </div>
                    )}
                </div>

                {quote.hasFocus && (
                  <div className="space-y-1">
                    <h2 className="mt-2 mb-4 text-blue-cnf font-bold text-xl">
                      Pickup options
                    </h2>
                    <AddressOptions property="pickupOptions" />
                  </div>
                )}
              </div>
              <div className="col-span-6 border-b pb-3 md:border-b-0 md:pb-0 md:col-span-3">
                <div className="to mb-3">
                  <div className="relative">
                    {quote.tab === "international" && (
                      <div className="mb-2">
                        <label className="text-xs text-gray-500 block mb-1">
                          Delivery country
                        </label>
                        <div className="h-10">
                        <AddressCountrySelector property="toAddress" />
                        </div>
                      </div>
                    )}
                    <div className="relative">
                      <div className="h-16 pb-6">
                        <AddressSuburbSelector
                          label="Delivery suburb"
                          property="toAddress"
                          tabIndex={0}
                        />
                      </div>
                    </div>
                    {quote.hasFocus && (
                      <div className="flex flex-col mt-2">
                        <label className="text-xs text-gray-500 block mb-1 mt-1">Delivery address type</label>
                        <AddressType property="toAddress" simple={true} />
                      </div>
                    )}
                  </div>
                </div>
                {quote.hasFocus && (
                  <div className="space-y-1">
                    <h2 className="mt-2 mb-4 text-blue-cnf font-bold text-xl">
                      Delivery options
                    </h2>
                    <AddressOptions property="deliveryOptions" />
                  </div>
                )}
              </div>

              {quote.hasFocus && (
                <div className="col-span-6">
                  <Items
                    storageKey={`guest_form`}
                    simple={true}
                  />
                </div>
              )}

              {quote.hasFocus && (
                <>
                  <div className="col-span-6 md:col-span-2">
                    <label className="text-xs text-gray-500 block mb-1">
                      Promo code
                    </label>
                    <Field
                      name="promoCode"
                      type="text"
                      className="block border border-primary text-sm py-3 px-3 rounded text-gray-700 w-full"
                    />
                  </div>
                  <div className="col-span-6 md:col-span-2 md:col-start-5">
                    <label className="text-xs text-gray-500">&nbsp;</label>

                    <div className="flex w-full space-x-2">
                      {props.onGetQuotes && (
                        <button
                          type="button"
                          onClick={handleCancel}
                          className="flex-1 px-6 py-3 text-primary border rounded border-primary bg-white hover:bg-gray-100"
                        >
                          Cancel
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={() => handleSubmit()}
                        className="flex-1 px-6 py-3 text-white border-primary border bg-primary hover:bg-blue-cnf transition-all duration-100 rounded text-sm"
                      >
                        Get quotes
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="mt-6">
              <ValidationErrors errors={errors} />
            </div>
          </div>
        )
      }}
    </Formik>
  )
}
