/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Customer extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Customer
         * @name Register
         * @request POST:/api/services/app/Customer/Register
         * @response `200` `CustomersCustomerRegisterOutput` Success
         */
        this.Register = (data, params = {}) => this.request({
            path: `/api/services/app/Customer/Register`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Customer
         * @name GetCustomers
         * @request GET:/api/services/app/Customer/GetCustomers
         * @response `200` `PagedResultCustomersCustomerList` Success
         */
        this.GetCustomers = (query, params = {}) => this.request({
            path: `/api/services/app/Customer/GetCustomers`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Customer
         * @name GetCustomerForEdit
         * @request GET:/api/services/app/Customer/GetCustomerForEdit
         * @response `200` `CustomersGetCustomerForEditOutput` Success
         */
        this.GetCustomerForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/Customer/GetCustomerForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Customer
         * @name UpdateCustomerAccount
         * @request PUT:/api/services/app/Customer/UpdateCustomerAccount
         * @response `200` `void` Success
         */
        this.UpdateCustomerAccount = (data, params = {}) => this.request({
            path: `/api/services/app/Customer/UpdateCustomerAccount`,
            method: "PUT",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Customer
         * @name CreateOrUpdateCustomer
         * @request POST:/api/services/app/Customer/CreateOrUpdateCustomer
         * @response `200` `CustomersGetCustomerForEditOutput` Success
         */
        this.CreateOrUpdateCustomer = (data, params = {}) => this.request({
            path: `/api/services/app/Customer/CreateOrUpdateCustomer`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Customer
         * @name AddCustomerNote
         * @request POST:/api/services/app/Customer/AddCustomerNote
         * @response `200` `CustomersGetCustomerForEditOutput` Success
         */
        this.AddCustomerNote = (data, params = {}) => this.request({
            path: `/api/services/app/Customer/AddCustomerNote`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Customer
         * @name DeleteCustomer
         * @request DELETE:/api/services/app/Customer/DeleteCustomer
         * @response `200` `void` Success
         */
        this.DeleteCustomer = (query, params = {}) => this.request({
            path: `/api/services/app/Customer/DeleteCustomer`,
            method: "DELETE",
            query: query,
            ...params,
        });
        /**
         * No description
         *
         * @tags Customer
         * @name GetCustomersTypes
         * @request GET:/api/services/app/Customer/GetCustomersTypes
         * @response `200` `(CustomersTypeInformation)[]` Success
         */
        this.GetCustomersTypes = (params = {}) => this.request({
            path: `/api/services/app/Customer/GetCustomersTypes`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Customer
         * @name GetCurrentCustomer
         * @request GET:/api/services/app/Customer/GetCurrentCustomer
         * @response `200` `CustomersGetCustomerForEditOutput` Success
         */
        this.GetCurrentCustomer = (params = {}) => this.request({
            path: `/api/services/app/Customer/GetCurrentCustomer`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Customer
         * @name GetCustomerFinanceSummary
         * @request GET:/api/services/app/Customer/GetCustomerFinanceSummary
         * @response `200` `FinanceCustomersCustomerFinanceSummary` Success
         */
        this.GetCustomerFinanceSummary = (query, params = {}) => this.request({
            path: `/api/services/app/Customer/GetCustomerFinanceSummary`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Customer
         * @name GetCustomerPaymentToken
         * @request GET:/api/services/app/Customer/GetCustomerPaymentToken
         * @response `200` `EntitySystemString` Success
         */
        this.GetCustomerPaymentToken = (query, params = {}) => this.request({
            path: `/api/services/app/Customer/GetCustomerPaymentToken`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Customer
         * @name GetFinanceStatement
         * @request GET:/api/services/app/Customer/GetFinanceStatement
         * @response `200` `FinanceCustomerFinanceStatement` Success
         */
        this.GetFinanceStatement = (query, params = {}) => this.request({
            path: `/api/services/app/Customer/GetFinanceStatement`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    }
}
