/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Token extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Token
         * @name GuestToken
         * @request POST:/api/services/app/Token/GuestToken
         * @response `200` `AuthorizationTokenGetTokenOutput` Success
         */
        this.GuestToken = (data, params = {}) => this.request({
            path: `/api/services/app/Token/GuestToken`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    }
}
