/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class CustomerSettings extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags CustomerSettings
         * @name GetAllSettings
         * @request GET:/api/services/app/CustomerSettings/GetAllSettings
         * @response `200` `CustomerSettingsSettingEdit` Success
         */
        this.GetAllSettings = (query, params = {}) => this.request({
            path: `/api/services/app/CustomerSettings/GetAllSettings`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CustomerSettings
         * @name GetECommerceStoreSettings
         * @request GET:/api/services/app/CustomerSettings/GetECommerceStoreSettings
         * @response `200` `(CustomerSettingsStoreSettingList)[]` Success
         */
        this.GetECommerceStoreSettings = (query, params = {}) => this.request({
            path: `/api/services/app/CustomerSettings/GetECommerceStoreSettings`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CustomerSettings
         * @name UpdateAllSettings
         * @request PUT:/api/services/app/CustomerSettings/UpdateAllSettings
         * @response `200` `void` Success
         */
        this.UpdateAllSettings = (data, params = {}) => this.request({
            path: `/api/services/app/CustomerSettings/UpdateAllSettings`,
            method: "PUT",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags CustomerSettings
         * @name UpdateECommerceStoreSettings
         * @request PUT:/api/services/app/CustomerSettings/UpdateECommerceStoreSettings
         * @response `200` `void` Success
         */
        this.UpdateECommerceStoreSettings = (data, params = {}) => this.request({
            path: `/api/services/app/CustomerSettings/UpdateECommerceStoreSettings`,
            method: "PUT",
            body: data,
            type: ContentType.Json,
            ...params,
        });
    }
}
