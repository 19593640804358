import * as React from "react"
import ApiProvider from "@shipeedo/ui-library/contexts/api"

import QuoteProvider from "./contexts/quote-context"
import QuoteWidget from "./components/quote/index"
import QuoteWrapper from "./components/quote/quote-wrapper"
import QuoteWidgetTabs from "./components/quote/quote-widget-tabs"
import { QueryClient, QueryClientProvider } from "react-query"

interface BookingWidgetProps {
  config?: BookingWidgetConfig
}

function BookingWidget(props: BookingWidgetProps) {
  const { config, ...other } = props

  const queryClient = new QueryClient()

  return (
    <ApiProvider endpoint={"https://members.couriersandfreight.com.au"}>
      <QueryClientProvider client={queryClient}>
        <QuoteProvider config={config}>
          <QuoteWrapper>
            <div className="hidden md:block">
              <QuoteWidgetTabs />
            </div>
            <div className="block md:hidden">
              <QuoteWidgetTabs tabs={["domestic", "international"]} />
            </div>
            <QuoteWidget />
          </QuoteWrapper>
        </QuoteProvider>
      </QueryClientProvider>
    </ApiProvider>
  )
}

if (typeof window !== "undefined") {
  // @ts-ignore
  window.BookingWidget = BookingWidget
}

export { BookingWidget }
