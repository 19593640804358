/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Outboundcomms extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags OutboundCommunication
         * @name SendEmail
         * @request POST:/api/communication/outboundcomms/send-email
         * @response `200` `string` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.SendEmail = (data, params = {}) => this.request({
            path: `/api/communication/outboundcomms/send-email`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    }
}
