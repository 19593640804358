import * as React from "react"
import { Formik, Field, Form } from "formik"
import * as libphonenumber from "libphonenumber-js"

import { ApiContext } from "@shipeedo/ui-library/contexts/api"
import AddressCountry from "@shipeedo/ui-library/components/address/country"
import AddressSuburbSelector from "@shipeedo/ui-library/components/address/suburb-selector"
import Loading from "@shipeedo/ui-library/components/loading"
import ValidationErrors from "@shipeedo/ui-library/components/form/validation-errors"

import { QuoteContext } from "../../contexts/quote-context"
import AddressPortSelector from "../../components/port-selector"

enum FormState {
  Idle,
  Submitting,
  Submitted,
}

export default function FreightShipmentForm() {
  let { endpoint } = React.useContext(ApiContext)
  let { hasFocus } = React.useContext(QuoteContext)
  let [formState, setFormState] = React.useState<FormState>(FormState.Idle)
  let [errors, setErrors] = React.useState<{ message: string }[]>([])

  const handleValidate = (values) => {
    let errors = {}
    if (!values.fromAddress.countryCode) {
      errors["fromAddress.countryCode"] = "You must pick an origin country"
    }
    if (!values.toAddress.countryCode) {
      errors["toAddress.countryCode"] = "You must pick a destination country"
    }

    if (!values.fromAddress.suburb && !values.fromAddress.port) {
      let key = values.movementType.startsWith("door-to")
        ? "fromAddress.postCode"
        : "fromAddress.port"
      errors[key] = "You must select an origin"
    }

    if (!values.toAddress.suburb && !values.toAddress.port) {
      let key = values.movementType.endsWith("to-door")
        ? "toAddress.postCode"
        : "toAddress.port"
      errors[key] = "You must select a destination"
    }

    if (values.mode !== "fcl") {
      if (!values.weight) {
        errors["weight"] = "You must supply a weight"
      }
      if (!values.volume) {
        errors["volume"] = "You must provide a volume"
      }
    }
    if (!values.description) {
      errors["description"] =
        values.mode === "fcl"
          ? "You must provide the contents of your container"
          : "You must provide a description of your items"
    }

    if (!values.name) {
      errors["name"] = "You must provide your name"
    }

    if (!values.telephone) {
      errors["telephone"] = "You must provide a contact phone number"
    }
    if (values.telephone) {
      if (!/[0-9 ]+/g.test(values.telephone)) {
        errors["telephone"] = "Please provide a valid phone number"
      }

      let validFromCountry = libphonenumber.isValidNumber(
        values.telephone,
        values.fromAddress.countryCode
      )
      let validToCountry = libphonenumber.isValidNumber(
        values.telephone,
        values.toAddress.countryCode
      )
      if (!validFromCountry && !validToCountry) {
        errors["telephone"] =
          "The phone number is not valid for either of the countries you have selected"
      }
    }

    if (!values.email) {
      errors["email"] = "You must provide an email address"
    }

    if (values.email) {
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
          values.email
        )
      ) {
        errors["email"] = "You must provide a valid email address"
      }
    }

    setErrors(
      Object.keys(errors).map((key) => ({
        message: errors[key],
        selector: `[name='${key}']`,
      }))
    )

    return errors
  }

  const handleOnSubmit = (values, { setSubmitting }) => {
    setFormState(FormState.Submitting)

    fetch(`${endpoint}/bookings-guest/api/air-sea-quote${window.location.search}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        ...values,
      }),
      mode: "cors",
    })

    setTimeout(() => {
      setSubmitting(false)
      setFormState(FormState.Submitted)
    }, 1000)
  }

  if (formState === FormState.Submitting) {
    return (
      <div className="p-4">
        <Loading className="w-10 h-10 mx-auto text-primary my-10" />
      </div>
    )
  }

  if (formState === FormState.Submitted) {
    return (
      <div className="p-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-16 h-16 text-primary mx-auto mt-10"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <p className="w-full text-center mt-4 mb-10 text-gray-900 font-medium text-xl">
          Thanks, one of our freight experts will be in touch shortly.
        </p>
      </div>
    )
  }

  return (
    <div className="p-4">
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        validate={handleValidate}
        onSubmit={handleOnSubmit}
        initialValues={{
          mode: "lcl",
          movementType: "door-to-door",
          fromAddress: {},
          toAddress: {},
          itemType: "Pallet",
          quantity: 1,
        }}
      >
        {({ values, isSubmitting }) => (
          <Form aria-autocomplete="none">
            <div className="grid grid-cols-6 gap-4">
              <div className="col-span-6 md:col-span-3">
                <label className="text-sm text-gray-500 mb-1 block">Mode</label>
                <Field
                  component="select"
                  name="mode"
                  className="text-sm text-gray-900 border rounded p-3 w-full"
                >
                  <option value="lcl">Ocean LCL</option>
                  <option value="fcl">Ocean FCL</option>
                  <option value="air">Air</option>
                </Field>
              </div>

              <div className="col-span-6 md:col-span-3">
                <label className="text-sm text-gray-500 mb-1 block">
                  Movement type
                </label>
                <Field
                  component="select"
                  name="movementType"
                  className="text-sm text-gray-900 border rounded p-3 w-full"
                >
                  <option value="door-to-door">Door to Door</option>
                  <option value="door-to-port">Door to Port</option>
                  <option value="port-to-door">Port to Door</option>
                  <option value="port-to-port">Port to Port</option>
                </Field>
              </div>

              <div className="col-span-6 md:col-span-3">
                <label className="text-sm text-gray-500 mb-1 block">
                  Origin country
                </label>
                <AddressCountry
                  property="fromAddress"
                  ignoreValidation={true}
                />
              </div>

              <div className="col-span-6 md:col-span-3">
                <label className="text-sm text-gray-500 mb-1 block">
                  Destination country
                </label>
                <AddressCountry property="toAddress" ignoreValidation={true} />
              </div>

              {hasFocus && (
                <>
                  <div className="col-span-6 md:col-span-3">
                    {values.movementType.startsWith("door-to") ? (
                      <>
                        <label className="text-sm text-gray-500 mb-1 block">
                          Origin suburb / postcode
                        </label>
                        <AddressSuburbSelector property="fromAddress" />
                      </>
                    ) : (
                      <>
                        <label className="text-sm text-gray-500 mb-1 block">
                          Origin port
                        </label>
                        <AddressPortSelector
                          property="fromAddress"
                          mode={values.mode !== "air" ? "sea" : "air"}
                        />
                      </>
                    )}
                  </div>

                  <div className="col-span-6 md:col-span-3">
                    {values.movementType.endsWith("-to-door") ? (
                      <>
                        <label className="text-sm text-gray-500 mb-1 block">
                          Destination suburb / postcode
                        </label>
                        <AddressSuburbSelector property="toAddress" />
                      </>
                    ) : (
                      <>
                        <label className="text-sm text-gray-500 mb-1 block">
                          Destination port
                        </label>
                        <AddressPortSelector
                          property="toAddress"
                          mode={values.mode !== "air" ? "sea" : "air"}
                        />
                      </>
                    )}
                  </div>

                  <div
                    className={`${
                      values.mode === "fcl"
                        ? "col-span-4 md:col-span-3"
                        : "col-span-4 md:col-span-1"
                    }`}
                  >
                    <label className="text-sm text-gray-500 mb-1 block">
                      {values.mode === "fcl"
                        ? "Container type"
                        : "Packaging type"}
                    </label>

                    {values.mode === "fcl" ? (
                      <Field
                        component="select"
                        name="itemType"
                        className="text-sm text-gray-900 border rounded p-3 w-full"
                      >
                        <option>Please select</option>
                        <option value="20-foot">20' General Purpose</option>
                        <option value="40-foot">40' General Purpose</option>
                        <option value="40-foot-highcube">40' High Cube</option>
                      </Field>
                    ) : (
                      <Field
                        name={"itemType"}
                        component="select"
                        className="relative flex p-3 self-center bg-white border rounded w-full text-sm text-gray-900"
                      >
                        <option>Box</option>
                        <option>Carton</option>
                        <option>Pallet</option>
                        <option>Skid</option>
                        <option>Crate</option>
                        <option>Roll</option>
                        <option>Drum</option>
                      </Field>
                    )}
                  </div>

                  <div className="col-span-2 md:col-span-1">
                    <label className="text-sm text-gray-500 mb-1 block">
                      Quantity
                    </label>
                    <Field
                      type="number"
                      className="border p-3 rounded text-sm w-full"
                      name="quantity"
                    />
                  </div>

                  {values.mode !== "fcl" && (
                    <>
                      <div className="col-span-3 md:col-span-1">
                        <label className="text-sm text-gray-500 mb-1 block">
                          Weight/piece (KG)
                        </label>
                        <Field
                          type="number"
                          className="border p-3 rounded text-sm w-full"
                          name="weight"
                          min={1}
                          placeholder="KG"
                        />
                      </div>

                      <div className="col-span-3 md:col-span-1">
                        <label className="text-sm text-gray-500 mb-1 block">
                          Volume/piece (m<sup>3</sup>)
                        </label>
                        <Field
                          type="number"
                          className="border p-3 rounded text-sm w-full"
                          name="volume"
                          min={0.0}
                          step={0.05}
                          placeholder="m3"
                        />
                      </div>
                    </>
                  )}

                  <div className="col-span-6 md:col-span-2">
                    <label className="text-sm text-gray-500 mb-1 block">
                      {values.mode === "fcl"
                        ? "Contents of container"
                        : "Description of goods"}
                    </label>
                    <Field
                      type="text"
                      className="border p-3 rounded text-sm w-full text-gray-900"
                      name="description"
                    />
                  </div>

                  <div className="col-span-6 border-t pt-4">
                    <h2 className="text-gray-500 font-medium -mb-2">
                      Your contact details
                    </h2>
                  </div>
                  <div className="col-span-6 md:col-span-2">
                    <label className="text-sm text-gray-500 mb-1 block">
                      Your name
                    </label>
                    <Field
                      type="text"
                      className="border p-3 rounded text-sm w-full text-gray-900"
                      name="name"
                    />
                  </div>

                  <div className="col-span-6 md:col-span-2">
                    <label className="text-sm text-gray-500 mb-1 block">
                      Telephone
                    </label>
                    <Field
                      type="text"
                      className="border p-3 rounded text-sm w-full text-gray-900"
                      name="telephone"
                    />
                  </div>

                  <div className="col-span-6 md:col-span-2">
                    <label className="text-sm text-gray-500 mb-1 block">
                      Email address
                    </label>
                    <Field
                      type="text"
                      className="border p-3 rounded text-sm w-full text-gray-900"
                      name="email"
                    />
                  </div>

                  <div className={`block col-span-6 mt-4`}>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="flex-1 px-12 py-3 block mx-auto text-white border-primary border bg-primary hover:bg-blue-cnf transition-all duration-100 rounded text-sm"
                    >
                      Get a quote
                    </button>
                  </div>

                  {errors.length > 0 && (
                    <div className="col-span-6">
                      <ValidationErrors errors={errors} />
                    </div>
                  )}
                </>
              )}
            </div>

            {/* <div className="col-span-3 md:col-span-2">
                <label className="text-sm text-gray-500 mb-1 block">Incoterm</label>
                <Field
                  component="select"
                  name="incoterm"
                  className="text-sm text-gray-900 border rounded p-3 w-full"
                >
                  <option value="DAP">Delivered at place</option>
                  <option value="DDP">Delivery duty paid</option>
                  <option value="DPU">Delivered at place unloaded</option>
                  <option value="EXW">Ex Works</option>
                </Field>
              </div> */}
          </Form>
        )}
      </Formik>
    </div>
  )
}
