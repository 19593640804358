/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Emailing extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Emailing
         * @name GetEmailingEvents
         * @request GET:/api/services/app/Emailing/GetEmailingEvents
         * @deprecated
         * @response `200` `(EmailingEmailingEventList)[]` Success
         */
        this.GetEmailingEvents = (params = {}) => this.request({
            path: `/api/services/app/Emailing/GetEmailingEvents`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Emailing
         * @name GetCommunicationTemplateDefinitions
         * @request GET:/api/services/app/Emailing/GetCommunicationTemplateDefinitions
         * @response `200` `ListResultEmailingEmailingEventList` Success
         */
        this.GetCommunicationTemplateDefinitions = (params = {}) => this.request({
            path: `/api/services/app/Emailing/GetCommunicationTemplateDefinitions`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Emailing
         * @name GetEmailTemplates
         * @request GET:/api/services/app/Emailing/GetEmailTemplates
         * @response `200` `PagedResultEmailingEmailTemplateList` Success
         */
        this.GetEmailTemplates = (query, params = {}) => this.request({
            path: `/api/services/app/Emailing/GetEmailTemplates`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Emailing
         * @name GetEmailTemplateForEdit
         * @request GET:/api/services/app/Emailing/GetEmailTemplateForEdit
         * @response `200` `EmailingGetEmailTemplateForEditOutput` Success
         */
        this.GetEmailTemplateForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/Emailing/GetEmailTemplateForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Emailing
         * @name CreateOrUpdateEmailTemplate
         * @request POST:/api/services/app/Emailing/CreateOrUpdateEmailTemplate
         * @response `200` `void` Success
         */
        this.CreateOrUpdateEmailTemplate = (data, params = {}) => this.request({
            path: `/api/services/app/Emailing/CreateOrUpdateEmailTemplate`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Emailing
         * @name DeleteEmailTemplate
         * @request DELETE:/api/services/app/Emailing/DeleteEmailTemplate
         * @response `200` `void` Success
         */
        this.DeleteEmailTemplate = (query, params = {}) => this.request({
            path: `/api/services/app/Emailing/DeleteEmailTemplate`,
            method: "DELETE",
            query: query,
            ...params,
        });
        /**
         * No description
         *
         * @tags Emailing
         * @name SendTestEmail
         * @request POST:/api/services/app/Emailing/SendTestEmail
         * @response `200` `void` Success
         */
        this.SendTestEmail = (data, params = {}) => this.request({
            path: `/api/services/app/Emailing/SendTestEmail`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
    }
}
