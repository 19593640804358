/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { HttpClient } from "./http-client.js";
export class Session extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Session
         * @name GetCurrentLoginInformations
         * @request GET:/api/services/app/Session/GetCurrentLoginInformations
         * @response `200` `SessionsGetCurrentLoginInformationsOutput` Success
         */
        this.GetCurrentLoginInformations = (params = {}) => this.request({
            path: `/api/services/app/Session/GetCurrentLoginInformations`,
            method: "GET",
            format: "json",
            ...params,
        });
    }
}
