/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Quote extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Quote
         * @name Quote
         * @request POST:/api/services/app/Quote/Quote
         * @response `200` `BookingQuotesQuoteOutput` Success
         */
        this.Quote = (data, params = {}) => this.request({
            path: `/api/services/app/Quote/Quote`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Quote
         * @name GetQuotes
         * @request GET:/api/services/app/Quote/GetQuotes
         * @response `200` `BookingQuotesQuoteResultOutput` Success
         */
        this.GetQuotes = (query, params = {}) => this.request({
            path: `/api/services/app/Quote/GetQuotes`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    }
}
