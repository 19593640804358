/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Comment extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Comment
         * @name GetCommentStatus
         * @request GET:/api/communication/comment/get-comment-status
         * @response `200` `(string)[]` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.GetCommentStatus = (params = {}) => this.request({
            path: `/api/communication/comment/get-comment-status`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Comment
         * @name GetComment
         * @request GET:/api/communication/comment/get-comment
         * @response `200` `CommunicationSharedComment` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.GetComment = (query, params = {}) => this.request({
            path: `/api/communication/comment/get-comment`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Comment
         * @name GetDiscussionComments
         * @request GET:/api/communication/comment/get-discussion-comments
         * @response `200` `(CommunicationSharedComment)[]` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.GetDiscussionComments = (query, params = {}) => this.request({
            path: `/api/communication/comment/get-discussion-comments`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Comment
         * @name GetComments
         * @request GET:/api/communication/comment/get-comments
         * @response `200` `(CommunicationSharedComment)[]` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.GetComments = (query, params = {}) => this.request({
            path: `/api/communication/comment/get-comments`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Comment
         * @name CreateComment
         * @request POST:/api/communication/comment/create-comment
         * @response `200` `CommunicationSharedIdResponse` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.CreateComment = (data, params = {}) => this.request({
            path: `/api/communication/comment/create-comment`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Comment
         * @name UpdateComment
         * @request PUT:/api/communication/comment/update-comment
         * @response `200` `string` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.UpdateComment = (data, params = {}) => this.request({
            path: `/api/communication/comment/update-comment`,
            method: "PUT",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Comment
         * @name DeleteComment
         * @request DELETE:/api/communication/comment/delete-comment
         * @response `200` `void` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.DeleteComment = (query, params = {}) => this.request({
            path: `/api/communication/comment/delete-comment`,
            method: "DELETE",
            query: query,
            ...params,
        });
    }
}
