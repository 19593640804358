/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class CreditNote extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags CreditNote
         * @name GetCreditNotes
         * @request GET:/api/services/app/CreditNote/GetCreditNotes
         * @response `200` `PagedResultFinanceCreditNotesCreditNoteList` Success
         */
        this.GetCreditNotes = (query, params = {}) => this.request({
            path: `/api/services/app/CreditNote/GetCreditNotes`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CreditNote
         * @name GetCreditNoteForEdit
         * @request GET:/api/services/app/CreditNote/GetCreditNoteForEdit
         * @response `200` `FinanceCreditNotesGetCreditNoteForEditOutput` Success
         */
        this.GetCreditNoteForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/CreditNote/GetCreditNoteForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CreditNote
         * @name CreateCreditNoteRequest
         * @request POST:/api/services/app/CreditNote/CreateCreditNoteRequest
         * @response `200` `FinanceCreditNotesGetCreditNoteForEditOutput` Success
         */
        this.CreateCreditNoteRequest = (data, params = {}) => this.request({
            path: `/api/services/app/CreditNote/CreateCreditNoteRequest`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CreditNote
         * @name UpdateCreditNoteNotes
         * @request PUT:/api/services/app/CreditNote/UpdateCreditNoteNotes
         * @response `200` `FinanceCreditNotesGetCreditNoteForEditOutput` Success
         */
        this.UpdateCreditNoteNotes = (data, params = {}) => this.request({
            path: `/api/services/app/CreditNote/UpdateCreditNoteNotes`,
            method: "PUT",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CreditNote
         * @name ProcessCreditNoteRequest
         * @request POST:/api/services/app/CreditNote/ProcessCreditNoteRequest
         * @response `200` `FinanceCreditNotesGetCreditNoteForEditOutput` Success
         */
        this.ProcessCreditNoteRequest = (data, params = {}) => this.request({
            path: `/api/services/app/CreditNote/ProcessCreditNoteRequest`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CreditNote
         * @name DeleteCreditNote
         * @request DELETE:/api/services/app/CreditNote/DeleteCreditNote
         * @response `200` `void` Success
         */
        this.DeleteCreditNote = (query, params = {}) => this.request({
            path: `/api/services/app/CreditNote/DeleteCreditNote`,
            method: "DELETE",
            query: query,
            ...params,
        });
    }
}
