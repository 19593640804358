/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Shop extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Shop
         * @name GetEcommerceAttributes
         * @request GET:/api/services/app/Shop/GetEcommerceAttributes
         * @response `200` `(OrderShopsEcommerceAttributeList)[]` Success
         */
        this.GetEcommerceAttributes = (query, params = {}) => this.request({
            path: `/api/services/app/Shop/GetEcommerceAttributes`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Shop
         * @name CreateOrUpdateShop
         * @request POST:/api/services/app/Shop/CreateOrUpdateShop
         * @response `200` `OrderShopsGetShopForEditOutput` Success
         */
        this.CreateOrUpdateShop = (data, params = {}) => this.request({
            path: `/api/services/app/Shop/CreateOrUpdateShop`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Shop
         * @name GetShop
         * @request GET:/api/services/app/Shop/GetShop
         * @response `200` `OrderShopsGetShopForEditOutput` Success
         */
        this.GetShop = (query, params = {}) => this.request({
            path: `/api/services/app/Shop/GetShop`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Shop
         * @name GetShops
         * @request GET:/api/services/app/Shop/GetShops
         * @response `200` `PagedResultOrderShopsGetShopListOutput` Success
         */
        this.GetShops = (query, params = {}) => this.request({
            path: `/api/services/app/Shop/GetShops`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Shop
         * @name DeleteShop
         * @request DELETE:/api/services/app/Shop/DeleteShop
         * @response `200` `void` Success
         */
        this.DeleteShop = (query, params = {}) => this.request({
            path: `/api/services/app/Shop/DeleteShop`,
            method: "DELETE",
            query: query,
            ...params,
        });
        /**
         * No description
         *
         * @tags Shop
         * @name GetAvailableEcommercePlatforms
         * @request GET:/api/services/app/Shop/GetAvailableEcommercePlatforms
         * @response `200` `(string)[]` Success
         */
        this.GetAvailableEcommercePlatforms = (params = {}) => this.request({
            path: `/api/services/app/Shop/GetAvailableEcommercePlatforms`,
            method: "GET",
            format: "json",
            ...params,
        });
    }
}
