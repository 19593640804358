import * as React from "react"
import { isFunction } from "formik"
import { GuestBooking } from "@shipeedo/ui-library/types/guest-booking"

const DEFAULT_BOOKING = {
  customer: {
    address: {},
  },
  fromAddress: {},
  toAddress: {},
}

type Booking = any
export enum CustomerType {
  sender,
  receiver,
  third_party,
}

interface IBookingContext {
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>

  booking: Partial<GuestBooking>
  setBooking: React.Dispatch<React.SetStateAction<Booking>>

  customerType: CustomerType
  setCustomerType: React.Dispatch<React.SetStateAction<CustomerType>>

  showQuoteForm: boolean
  setShowQuoteForm: React.Dispatch<React.SetStateAction<boolean>>

  showCompleteModal: boolean
  setShowCompleteModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const BookingContext = React.createContext<IBookingContext>({
  step: 1,
  setStep: (step: number) => {},

  booking: null,
  setBooking: () => {},

  customerType: null,
  setCustomerType: () => {},

  showQuoteForm: false,
  setShowQuoteForm: (show: boolean) => {},

  showCompleteModal: false,
  setShowCompleteModal: (show: boolean) => {},
})

export default function BookingProvider({ children, initialStep }) {
  let [step, setStep] = React.useState<number>(initialStep)
  let [booking, setBooking] = React.useState<Booking>(DEFAULT_BOOKING)
  let [customerType, setCustomerType] = React.useState<CustomerType>(null)
  let [showQuoteForm, setShowQuoteForm] = React.useState<boolean>(false)
  let [showCompleteModal, setShowCompleteModal] = React.useState<boolean>(false)

  let value = {
    step,
    setStep,
    booking,
    setBooking,
    customerType,
    setCustomerType,
    showQuoteForm,
    setShowQuoteForm,
    showCompleteModal,
    setShowCompleteModal,
  }

  return (
    <BookingContext.Provider value={value}>
      {isFunction(children) ? children(value) : children}
    </BookingContext.Provider>
  )
}
