/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Account extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Account
         * @name IsTenantAvailable
         * @request POST:/api/services/app/Account/IsTenantAvailable
         * @response `200` `AccountsIsTenantAvailableOutput` Success
         */
        this.IsTenantAvailable = (data, params = {}) => this.request({
            path: `/api/services/app/Account/IsTenantAvailable`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Account
         * @name SendPasswordResetCode
         * @request POST:/api/services/app/Account/SendPasswordResetCode
         * @response `200` `void` Success
         */
        this.SendPasswordResetCode = (data, params = {}) => this.request({
            path: `/api/services/app/Account/SendPasswordResetCode`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Account
         * @name ResetPassword
         * @request POST:/api/services/app/Account/ResetPassword
         * @response `200` `AccountsResetPasswordOutput` Success
         */
        this.ResetPassword = (data, params = {}) => this.request({
            path: `/api/services/app/Account/ResetPassword`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Account
         * @name SendEmailActivationLink
         * @request POST:/api/services/app/Account/SendEmailActivationLink
         * @response `200` `void` Success
         */
        this.SendEmailActivationLink = (data, params = {}) => this.request({
            path: `/api/services/app/Account/SendEmailActivationLink`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Account
         * @name ActivateEmail
         * @request POST:/api/services/app/Account/ActivateEmail
         * @response `200` `void` Success
         */
        this.ActivateEmail = (data, params = {}) => this.request({
            path: `/api/services/app/Account/ActivateEmail`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Account
         * @name Impersonate
         * @request POST:/api/services/app/Account/Impersonate
         * @response `200` `AccountsImpersonateOutput` Success
         */
        this.Impersonate = (data, params = {}) => this.request({
            path: `/api/services/app/Account/Impersonate`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Account
         * @name BackToImpersonator
         * @request POST:/api/services/app/Account/BackToImpersonator
         * @response `200` `AccountsImpersonateOutput` Success
         */
        this.BackToImpersonator = (params = {}) => this.request({
            path: `/api/services/app/Account/BackToImpersonator`,
            method: "POST",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Account
         * @name SwitchToLinkedAccount
         * @request POST:/api/services/app/Account/SwitchToLinkedAccount
         * @response `200` `AccountsSwitchToLinkedAccountOutput` Success
         */
        this.SwitchToLinkedAccount = (data, params = {}) => this.request({
            path: `/api/services/app/Account/SwitchToLinkedAccount`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    }
}
