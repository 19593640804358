"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
function KeyboardList(props) {
    var _a = React.useState(-1), index = _a[0], setIndex = _a[1];
    var listRef = React.createRef();
    var numberOfItems = React.Children.count(props.children);
    var handleKeyboardNavigation = function (e) {
        if (!["ArrowUp", "ArrowDown"].includes(e.key)) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        if (e.key === "ArrowUp") {
            // decrement index
            if (index > 0) {
                setIndex(index - 1);
            }
            else {
                setIndex(numberOfItems - 1);
            }
        }
        else if (e.key === "ArrowDown") {
            // increment index
            if (index < (numberOfItems - 1)) {
                setIndex(index + 1);
            }
            else {
                setIndex(0);
            }
        }
    };
    React.useEffect(function () {
        if (index > -1) {
            var buttons = listRef.current.querySelectorAll("li button");
            if (buttons[index]) {
                buttons[index].focus();
            }
        }
    }, [index]);
    React.useEffect(function () {
        // @ts-ignore
        window.addEventListener('keydown', handleKeyboardNavigation);
        return function () {
            // @ts-ignore
            window.removeEventListener('keydown', handleKeyboardNavigation);
        };
    }, [index]);
    var children = React.Children.map(props.children, function (child, i) {
        if (React.isValidElement(child)) {
            var component = React.cloneElement(child, {
                className: [
                    child.props.className || "",
                    i === index ? "selected" : ""
                ].join(" ")
            });
            return component;
        }
    });
    return (React.createElement("ul", __assign({ ref: listRef }, props), children));
}
exports.default = KeyboardList;
