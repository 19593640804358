/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class CustomerFrequentAddress extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags CustomerFrequentAddress
         * @name GetFrequentAddresses
         * @request GET:/api/services/app/CustomerFrequentAddress/GetFrequentAddresses
         * @response `200` `PagedResultCustomerFrequentAddressesFrequentAddressList` Success
         */
        this.GetFrequentAddresses = (query, params = {}) => this.request({
            path: `/api/services/app/CustomerFrequentAddress/GetFrequentAddresses`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CustomerFrequentAddress
         * @name GetFrequentAddressForEdit
         * @request GET:/api/services/app/CustomerFrequentAddress/GetFrequentAddressForEdit
         * @response `200` `CustomerFrequentAddressesGetFrequentAddressForEditOutput` Success
         */
        this.GetFrequentAddressForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/CustomerFrequentAddress/GetFrequentAddressForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CustomerFrequentAddress
         * @name CreateOrUpdateFrequentAddress
         * @request POST:/api/services/app/CustomerFrequentAddress/CreateOrUpdateFrequentAddress
         * @response `200` `void` Success
         */
        this.CreateOrUpdateFrequentAddress = (data, params = {}) => this.request({
            path: `/api/services/app/CustomerFrequentAddress/CreateOrUpdateFrequentAddress`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags CustomerFrequentAddress
         * @name DeleteFrequentAddress
         * @request DELETE:/api/services/app/CustomerFrequentAddress/DeleteFrequentAddress
         * @response `200` `void` Success
         */
        this.DeleteFrequentAddress = (query, params = {}) => this.request({
            path: `/api/services/app/CustomerFrequentAddress/DeleteFrequentAddress`,
            method: "DELETE",
            query: query,
            ...params,
        });
        /**
         * No description
         *
         * @tags CustomerFrequentAddress
         * @name GetFrequentAddressesCsv
         * @request GET:/api/services/app/CustomerFrequentAddress/GetFrequentAddressesCSV
         * @response `200` `File` Success
         */
        this.GetFrequentAddressesCSV = (query, params = {}) => this.request({
            path: `/api/services/app/CustomerFrequentAddress/GetFrequentAddressesCSV`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CustomerFrequentAddress
         * @name ImportGetFrequentAddresses
         * @request POST:/api/services/app/CustomerFrequentAddress/ImportGetFrequentAddresses
         * @deprecated
         * @response `200` `CustomerFrequentAddressesImportFrequentAddressOutput` Success
         */
        this.ImportGetFrequentAddresses = (data, params = {}) => this.request({
            path: `/api/services/app/CustomerFrequentAddress/ImportGetFrequentAddresses`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CustomerFrequentAddress
         * @name ImportFrequentAddresses
         * @request POST:/api/services/app/CustomerFrequentAddress/ImportFrequentAddresses
         * @response `200` `CustomerFrequentAddressesImportFrequentAddressOutput` Success
         */
        this.ImportFrequentAddresses = (data, params = {}) => this.request({
            path: `/api/services/app/CustomerFrequentAddress/ImportFrequentAddresses`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    }
}
