/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class CarrierServices extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags CarrierServices
         * @name GetCarrierServices
         * @request GET:/api/services/app/CarrierServices/GetCarrierServices
         * @response `200` `PagedResultCarrierServicesCarrierServiceList` Success
         */
        this.GetCarrierServices = (query, params = {}) => this.request({
            path: `/api/services/app/CarrierServices/GetCarrierServices`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CarrierServices
         * @name GetCarrierServiceForEdit
         * @request GET:/api/services/app/CarrierServices/GetCarrierServiceForEdit
         * @response `200` `CarrierServicesGetCarrierServiceForEditOutput` Success
         */
        this.GetCarrierServiceForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/CarrierServices/GetCarrierServiceForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CarrierServices
         * @name CreateOrUpdateCarrierService
         * @request POST:/api/services/app/CarrierServices/CreateOrUpdateCarrierService
         * @response `200` `void` Success
         */
        this.CreateOrUpdateCarrierService = (data, params = {}) => this.request({
            path: `/api/services/app/CarrierServices/CreateOrUpdateCarrierService`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags CarrierServices
         * @name DeleteCarrierService
         * @request DELETE:/api/services/app/CarrierServices/DeleteCarrierService
         * @response `200` `void` Success
         */
        this.DeleteCarrierService = (query, params = {}) => this.request({
            path: `/api/services/app/CarrierServices/DeleteCarrierService`,
            method: "DELETE",
            query: query,
            ...params,
        });
        /**
         * No description
         *
         * @tags CarrierServices
         * @name GetServices
         * @request GET:/api/services/app/CarrierServices/GetServices
         * @response `200` `PagedResultCarrierServicesServiceList` Success
         */
        this.GetServices = (query, params = {}) => this.request({
            path: `/api/services/app/CarrierServices/GetServices`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CarrierServices
         * @name GetServiceForEdit
         * @request GET:/api/services/app/CarrierServices/GetServiceForEdit
         * @response `200` `CarrierServicesGetServiceForEditOutput` Success
         */
        this.GetServiceForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/CarrierServices/GetServiceForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CarrierServices
         * @name CreateOrUpdateService
         * @request POST:/api/services/app/CarrierServices/CreateOrUpdateService
         * @response `200` `void` Success
         */
        this.CreateOrUpdateService = (data, params = {}) => this.request({
            path: `/api/services/app/CarrierServices/CreateOrUpdateService`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags CarrierServices
         * @name DeleteService
         * @request DELETE:/api/services/app/CarrierServices/DeleteService
         * @response `200` `void` Success
         */
        this.DeleteService = (query, params = {}) => this.request({
            path: `/api/services/app/CarrierServices/DeleteService`,
            method: "DELETE",
            query: query,
            ...params,
        });
    }
}
