/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class PromoCode extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags PromoCode
         * @name GetPromocodeForEdit
         * @request GET:/api/services/app/PromoCode/GetPromocodeForEdit
         * @response `200` `BookingPromoCodesGetPromocodeForEditOutput` Success
         */
        this.GetPromocodeForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/PromoCode/GetPromocodeForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags PromoCode
         * @name GetPromoCodes
         * @request GET:/api/services/app/PromoCode/GetPromoCodes
         * @response `200` `PagedResultBookingPromoCodesPromoCodeList` Success
         */
        this.GetPromoCodes = (query, params = {}) => this.request({
            path: `/api/services/app/PromoCode/GetPromoCodes`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags PromoCode
         * @name DeletePromocode
         * @request DELETE:/api/services/app/PromoCode/DeletePromocode
         * @response `200` `void` Success
         */
        this.DeletePromocode = (query, params = {}) => this.request({
            path: `/api/services/app/PromoCode/DeletePromocode`,
            method: "DELETE",
            query: query,
            ...params,
        });
        /**
         * No description
         *
         * @tags PromoCode
         * @name GetMapCarrierAccountPromocode
         * @request GET:/api/services/app/PromoCode/GetMapCarrierAccountPromocode
         * @response `200` `void` Success
         */
        this.GetMapCarrierAccountPromocode = (params = {}) => this.request({
            path: `/api/services/app/PromoCode/GetMapCarrierAccountPromocode`,
            method: "GET",
            ...params,
        });
        /**
         * No description
         *
         * @tags PromoCode
         * @name CreateOrUpdatePromocode
         * @request POST:/api/services/app/PromoCode/CreateOrUpdatePromocode
         * @response `200` `BookingPromoCodesGetPromocodeForEditOutput` Success
         */
        this.CreateOrUpdatePromocode = (data, params = {}) => this.request({
            path: `/api/services/app/PromoCode/CreateOrUpdatePromocode`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    }
}
