/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class CustomerReferences extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags CustomerReferences
         * @name GetReferences
         * @request GET:/api/services/app/CustomerReferences/GetReferences
         * @response `200` `PagedResultCustomerReferencesReferenceList` Success
         */
        this.GetReferences = (query, params = {}) => this.request({
            path: `/api/services/app/CustomerReferences/GetReferences`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CustomerReferences
         * @name GetReferenceForEdit
         * @request GET:/api/services/app/CustomerReferences/GetReferenceForEdit
         * @response `200` `CustomerReferencesGetReferenceForEditOutput` Success
         */
        this.GetReferenceForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/CustomerReferences/GetReferenceForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CustomerReferences
         * @name CreateOrUpdateReference
         * @request POST:/api/services/app/CustomerReferences/CreateOrUpdateReference
         * @response `200` `void` Success
         */
        this.CreateOrUpdateReference = (data, params = {}) => this.request({
            path: `/api/services/app/CustomerReferences/CreateOrUpdateReference`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags CustomerReferences
         * @name DeleteReference
         * @request DELETE:/api/services/app/CustomerReferences/DeleteReference
         * @response `200` `void` Success
         */
        this.DeleteReference = (query, params = {}) => this.request({
            path: `/api/services/app/CustomerReferences/DeleteReference`,
            method: "DELETE",
            query: query,
            ...params,
        });
    }
}
