/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Discussion extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Discussion
         * @name GetDiscussionType
         * @request GET:/api/communication/discussion/get-discussion-type
         * @response `200` `(string)[]` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.GetDiscussionType = (params = {}) => this.request({
            path: `/api/communication/discussion/get-discussion-type`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Discussion
         * @name GetDiscussionStatus
         * @request GET:/api/communication/discussion/get-discussion-status
         * @response `200` `(string)[]` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.GetDiscussionStatus = (params = {}) => this.request({
            path: `/api/communication/discussion/get-discussion-status`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Discussion
         * @name GetDiscussion
         * @request GET:/api/communication/discussion/get-discussion
         * @response `200` `CommunicationSharedDiscussion` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.GetDiscussion = (query, params = {}) => this.request({
            path: `/api/communication/discussion/get-discussion`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Discussion
         * @name GetDiscussions
         * @request GET:/api/communication/discussion/get-discussions
         * @response `200` `(CommunicationSharedDiscussion)[]` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.GetDiscussions = (query, params = {}) => this.request({
            path: `/api/communication/discussion/get-discussions`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Discussion
         * @name CreateDiscussion
         * @request POST:/api/communication/discussion/create-discussion
         * @response `200` `CommunicationSharedIdResponse` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.CreateDiscussion = (data, params = {}) => this.request({
            path: `/api/communication/discussion/create-discussion`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Discussion
         * @name UpdateDiscussion
         * @request PUT:/api/communication/discussion/update-discussion
         * @response `200` `CommunicationSharedIdResponse` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.UpdateDiscussion = (data, params = {}) => this.request({
            path: `/api/communication/discussion/update-discussion`,
            method: "PUT",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Discussion
         * @name DeleteDiscussion
         * @request DELETE:/api/communication/discussion/delete-discussion
         * @response `200` `void` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.DeleteDiscussion = (query, params = {}) => this.request({
            path: `/api/communication/discussion/delete-discussion`,
            method: "DELETE",
            query: query,
            ...params,
        });
    }
}
