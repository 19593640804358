/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Reconciliation extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Reconciliation
         * @name ImportCsv
         * @request POST:/api/services/app/Reconciliation/ImportCSV
         * @response `200` `void` Success
         */
        this.ImportCSV = (data, params = {}) => this.request({
            path: `/api/services/app/Reconciliation/ImportCSV`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Reconciliation
         * @name ExportCsv
         * @request POST:/api/services/app/Reconciliation/ExportCSV
         * @response `200` `File` Success
         */
        this.ExportCSV = (data, params = {}) => this.request({
            path: `/api/services/app/Reconciliation/ExportCSV`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Reconciliation
         * @name GetReconciliations
         * @request GET:/api/services/app/Reconciliation/GetReconciliations
         * @response `200` `PagedResultBookingReconciliationsListReconciliation` Success
         */
        this.GetReconciliations = (query, params = {}) => this.request({
            path: `/api/services/app/Reconciliation/GetReconciliations`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Reconciliation
         * @name GetReconciliationForApproval
         * @request GET:/api/services/app/Reconciliation/GetReconciliationForApproval
         * @response `200` `BookingReconciliationsGetReconciliationOutput` Success
         */
        this.GetReconciliationForApproval = (query, params = {}) => this.request({
            path: `/api/services/app/Reconciliation/GetReconciliationForApproval`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Reconciliation
         * @name ApproveReconciliation
         * @request POST:/api/services/app/Reconciliation/ApproveReconciliation
         * @response `200` `void` Success
         */
        this.ApproveReconciliation = (data, params = {}) => this.request({
            path: `/api/services/app/Reconciliation/ApproveReconciliation`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Reconciliation
         * @name DeleteReconciliation
         * @request DELETE:/api/services/app/Reconciliation/DeleteReconciliation
         * @response `200` `void` Success
         */
        this.DeleteReconciliation = (query, params = {}) => this.request({
            path: `/api/services/app/Reconciliation/DeleteReconciliation`,
            method: "DELETE",
            query: query,
            ...params,
        });
        /**
         * No description
         *
         * @tags Reconciliation
         * @name GetPendingRechargeInvoices
         * @request GET:/api/services/app/Reconciliation/GetPendingRechargeInvoices
         * @response `200` `PagedResultFinanceInvoicesInvoiceListItem` Success
         */
        this.GetPendingRechargeInvoices = (query, params = {}) => this.request({
            path: `/api/services/app/Reconciliation/GetPendingRechargeInvoices`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Reconciliation
         * @name RechargeInvoices
         * @request POST:/api/services/app/Reconciliation/RechargeInvoices
         * @response `200` `void` Success
         */
        this.RechargeInvoices = (data, params = {}) => this.request({
            path: `/api/services/app/Reconciliation/RechargeInvoices`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
    }
}
