import * as React from "react"
import { render } from "react-dom"
import { BookingWidget } from "./index"

function embed(config: any) {
  render(
    React.createElement(
      BookingWidget,
      {
        config,
      },
      null
    ),
    document.getElementById("quoteWidget")
  )
}

// @ts-expect-error
window.embedQuoteWidget = embed