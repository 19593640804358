/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Tenant extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags TenantCustomization
         * @name GetLogo
         * @request GET:/api/services/app/tenant/GetLogo
         * @response `200` `void` Success
         */
        this.GetLogo = (params = {}) => this.request({
            path: `/api/services/app/tenant/GetLogo`,
            method: "GET",
            ...params,
        });
        /**
         * No description
         *
         * @tags TenantCustomization
         * @name GetStyles
         * @request GET:/api/services/app/tenant/GetStyles
         * @response `200` `void` Success
         */
        this.GetStyles = (params = {}) => this.request({
            path: `/api/services/app/tenant/GetStyles`,
            method: "GET",
            ...params,
        });
        /**
         * No description
         *
         * @tags TenantCustomization
         * @name GetSidebarLinks
         * @request GET:/api/services/app/tenant/GetSidebarLinks
         * @response `200` `PagedResultSidebarLinkSidebarLinkList` Success
         */
        this.GetSidebarLinks = (query, params = {}) => this.request({
            path: `/api/services/app/tenant/GetSidebarLinks`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags TenantCustomization
         * @name GetSidebarLinkForEdit
         * @request GET:/api/services/app/tenant/GetSidebarLinkForEdit
         * @response `200` `SidebarLinkGetSidebarLinkForEditOutput` Success
         */
        this.GetSidebarLinkForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/tenant/GetSidebarLinkForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags TenantCustomization
         * @name CreateOrUpdateSidebarLink
         * @request POST:/api/services/app/tenant/CreateOrUpdateSidebarLink
         * @response `200` `void` Success
         */
        this.CreateOrUpdateSidebarLink = (data, params = {}) => this.request({
            path: `/api/services/app/tenant/CreateOrUpdateSidebarLink`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags TenantCustomization
         * @name DeleteSidebarLink
         * @request DELETE:/api/services/app/tenant/DeleteSidebarLink
         * @response `200` `void` Success
         */
        this.DeleteSidebarLink = (query, params = {}) => this.request({
            path: `/api/services/app/tenant/DeleteSidebarLink`,
            method: "DELETE",
            query: query,
            ...params,
        });
    }
}
