/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class ErrorReport extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags ErrorReport
         * @name CreateErrorReport
         * @request POST:/api/services/app/ErrorReport/CreateErrorReport
         * @response `200` `ErrorReportsErrorReportOutput` Success
         */
        this.CreateErrorReport = (data, params = {}) => this.request({
            path: `/api/services/app/ErrorReport/CreateErrorReport`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags ErrorReport
         * @name GetErrorReports
         * @request GET:/api/services/app/ErrorReport/GetErrorReports
         * @response `200` `PagedResultErrorReportList` Success
         */
        this.GetErrorReports = (query, params = {}) => this.request({
            path: `/api/services/app/ErrorReport/GetErrorReports`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    }
}
