import * as React from "react"

import { QuoteContext } from "../../contexts/quote-context"
import QuoteForm from "../../components/quote/quote-form"
import TrackingForm from "../../components/tracking"
import FreightShipmentForm from "../../components/freight"

export default function QuoteWidget() {
  let { tab } = React.useContext(QuoteContext)

  if (tab === "domestic" || tab === "international") {
    return <QuoteForm />
  }

  if (tab === "air_sea") {
    return <FreightShipmentForm />
  }

  if (tab === "tracking") {
    return <TrackingForm />
  }
}
