/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { HttpClient } from "./http-client.js";
export class CommonLookup extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags CommonLookup
         * @name GetInsurenceTypes
         * @request GET:/api/services/app/CommonLookup/GetInsurenceTypes
         * @response `200` `(CommonInsurenceLookupData)[]` Success
         */
        this.GetInsurenceTypes = (params = {}) => this.request({
            path: `/api/services/app/CommonLookup/GetInsurenceTypes`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CommonLookup
         * @name GetAllPackageTypes
         * @request GET:/api/services/app/CommonLookup/GetAllPackageTypes
         * @response `200` `(CommonLookupData)[]` Success
         */
        this.GetAllPackageTypes = (params = {}) => this.request({
            path: `/api/services/app/CommonLookup/GetAllPackageTypes`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CommonLookup
         * @name GetPackageType
         * @request GET:/api/services/app/CommonLookup/GetPackageType
         * @response `200` `CommonLookupData` Success
         */
        this.GetPackageType = (query, params = {}) => this.request({
            path: `/api/services/app/CommonLookup/GetPackageType`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CommonLookup
         * @name GetLocations
         * @request GET:/api/services/app/CommonLookup/GetLocations
         * @response `200` `PagedResultCommonSuburbList` Success
         */
        this.GetLocations = (query, params = {}) => this.request({
            path: `/api/services/app/CommonLookup/GetLocations`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CommonLookup
         * @name SearchLocations
         * @request GET:/api/services/app/CommonLookup/SearchLocations
         * @response `200` `PagedResultCommonSuburbSearchResult` Success
         */
        this.SearchLocations = (query, params = {}) => this.request({
            path: `/api/services/app/CommonLookup/SearchLocations`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CommonLookup
         * @name GetCountry
         * @request GET:/api/services/app/CommonLookup/GetCountry
         * @response `200` `PagedResultCommonCountryList` Success
         */
        this.GetCountry = (query, params = {}) => this.request({
            path: `/api/services/app/CommonLookup/GetCountry`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CommonLookup
         * @name GetAttributes
         * @request GET:/api/services/app/CommonLookup/GetAttributes
         * @response `200` `PagedResultCommonAttributeList` Success
         */
        this.GetAttributes = (query, params = {}) => this.request({
            path: `/api/services/app/CommonLookup/GetAttributes`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CommonLookup
         * @name GetSystemEvents
         * @request GET:/api/services/app/CommonLookup/GetSystemEvents
         * @response `200` `PagedResultSystemString` Success
         */
        this.GetSystemEvents = (params = {}) => this.request({
            path: `/api/services/app/CommonLookup/GetSystemEvents`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CommonLookup
         * @name GetCustomersTypes
         * @request GET:/api/services/app/CommonLookup/GetCustomersTypes
         * @response `200` `(CustomersTypeInformation)[]` Success
         */
        this.GetCustomersTypes = (params = {}) => this.request({
            path: `/api/services/app/CommonLookup/GetCustomersTypes`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CommonLookup
         * @name GetDurationTypes
         * @request GET:/api/services/app/CommonLookup/GetDurationTypes
         * @response `200` `(CustomersTypeInformation)[]` Success
         */
        this.GetDurationTypes = (params = {}) => this.request({
            path: `/api/services/app/CommonLookup/GetDurationTypes`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CommonLookup
         * @name GetCustomerStatusTypes
         * @request GET:/api/services/app/CommonLookup/GetCustomerStatusTypes
         * @response `200` `(CustomersTypeInformation)[]` Success
         */
        this.GetCustomerStatusTypes = (params = {}) => this.request({
            path: `/api/services/app/CommonLookup/GetCustomerStatusTypes`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CommonLookup
         * @name GetCustomerBusinessTypes
         * @request GET:/api/services/app/CommonLookup/GetCustomerBusinessTypes
         * @response `200` `(CustomersTypeInformation)[]` Success
         */
        this.GetCustomerBusinessTypes = (params = {}) => this.request({
            path: `/api/services/app/CommonLookup/GetCustomerBusinessTypes`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CommonLookup
         * @name GetDangerousGoodsCategories
         * @request GET:/api/services/app/CommonLookup/GetDangerousGoodsCategories
         * @response `200` `(BookingCommonDangerousGoodsCategory)[]` Success
         */
        this.GetDangerousGoodsCategories = (params = {}) => this.request({
            path: `/api/services/app/CommonLookup/GetDangerousGoodsCategories`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CommonLookup
         * @name GetDangerousGoodsUnCodes
         * @request GET:/api/services/app/CommonLookup/GetDangerousGoodsUNCodes
         * @response `200` `PagedResultBookingCommonDangerousGoodsUNCodeList` Success
         */
        this.GetDangerousGoodsUNCodes = (query, params = {}) => this.request({
            path: `/api/services/app/CommonLookup/GetDangerousGoodsUNCodes`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    }
}
