/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Markup extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Markup
         * @name CreateOrUpdateMarkupforCustomer
         * @request POST:/api/services/app/Markup/CreateOrUpdateMarkupforCustomer
         * @response `200` `void` Success
         */
        this.CreateOrUpdateMarkupforCustomer = (data, params = {}) => this.request({
            path: `/api/services/app/Markup/CreateOrUpdateMarkupforCustomer`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Markup
         * @name CreateOrUpdateMarkup
         * @request POST:/api/services/app/Markup/CreateOrUpdateMarkup
         * @response `200` `void` Success
         */
        this.CreateOrUpdateMarkup = (data, params = {}) => this.request({
            path: `/api/services/app/Markup/CreateOrUpdateMarkup`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Markup
         * @name GetCustomerMarkup
         * @request GET:/api/services/app/Markup/GetCustomerMarkup
         * @response `200` `BookingMarkupsMarkupCustomerOutput` Success
         */
        this.GetCustomerMarkup = (query, params = {}) => this.request({
            path: `/api/services/app/Markup/GetCustomerMarkup`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Markup
         * @name GetMarkup
         * @request GET:/api/services/app/Markup/GetMarkup
         * @response `200` `BookingMarkupsMarkupCarrierOutput` Success
         */
        this.GetMarkup = (params = {}) => this.request({
            path: `/api/services/app/Markup/GetMarkup`,
            method: "GET",
            format: "json",
            ...params,
        });
    }
}
