/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class CustomerProduct extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags CustomerProduct
         * @name GetProducts
         * @request GET:/api/services/app/CustomerProduct/GetProducts
         * @response `200` `PagedResultCustomerProductsProductList` Success
         */
        this.GetProducts = (query, params = {}) => this.request({
            path: `/api/services/app/CustomerProduct/GetProducts`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CustomerProduct
         * @name GetProductForEdit
         * @request GET:/api/services/app/CustomerProduct/GetProductForEdit
         * @response `200` `CustomerProductsGetProductForEditOutput` Success
         */
        this.GetProductForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/CustomerProduct/GetProductForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CustomerProduct
         * @name CreateOrUpdateProduct
         * @request POST:/api/services/app/CustomerProduct/CreateOrUpdateProduct
         * @response `200` `void` Success
         */
        this.CreateOrUpdateProduct = (data, params = {}) => this.request({
            path: `/api/services/app/CustomerProduct/CreateOrUpdateProduct`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags CustomerProduct
         * @name DeleteProduct
         * @request DELETE:/api/services/app/CustomerProduct/DeleteProduct
         * @response `200` `void` Success
         */
        this.DeleteProduct = (query, params = {}) => this.request({
            path: `/api/services/app/CustomerProduct/DeleteProduct`,
            method: "DELETE",
            query: query,
            ...params,
        });
        /**
         * No description
         *
         * @tags CustomerProduct
         * @name GetProductsCsv
         * @request GET:/api/services/app/CustomerProduct/GetProductsCSV
         * @response `200` `File` Success
         */
        this.GetProductsCSV = (query, params = {}) => this.request({
            path: `/api/services/app/CustomerProduct/GetProductsCSV`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CustomerProduct
         * @name ImportProducts
         * @request POST:/api/services/app/CustomerProduct/ImportProducts
         * @response `200` `CustomerProductsImportProductOutput` Success
         */
        this.ImportProducts = (data, params = {}) => this.request({
            path: `/api/services/app/CustomerProduct/ImportProducts`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    }
}
