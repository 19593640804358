/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { HttpClient } from "./http-client.js";
export class Configuration extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Configuration
         * @name GetConfiguration
         * @request GET:/api/services/app/Configuration/GetConfiguration
         * @response `200` `ConfigurationConfigaration` Success
         */
        this.GetConfiguration = (params = {}) => this.request({
            path: `/api/services/app/Configuration/GetConfiguration`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Configuration
         * @name GetBookingConfigurations
         * @request GET:/api/services/app/Configuration/GetBookingConfigurations
         * @response `200` `BookingConfigurationsBookingSetting` Success
         */
        this.GetBookingConfigurations = (params = {}) => this.request({
            path: `/api/services/app/Configuration/GetBookingConfigurations`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Configuration
         * @name GetCreditNoteOperations
         * @request GET:/api/services/app/Configuration/GetCreditNoteOperations
         * @response `200` `FinanceConfigurationsCreditNoteSettings` Success
         */
        this.GetCreditNoteOperations = (params = {}) => this.request({
            path: `/api/services/app/Configuration/GetCreditNoteOperations`,
            method: "GET",
            format: "json",
            ...params,
        });
    }
}
