/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class CarrierAccount extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags CarrierAccount
         * @name GetCarrierCodes
         * @request GET:/api/services/app/CarrierAccount/GetCarrierCodes
         * @response `200` `(string)[]` Success
         */
        this.GetCarrierCodes = (params = {}) => this.request({
            path: `/api/services/app/CarrierAccount/GetCarrierCodes`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CarrierAccount
         * @name GetCarrierAttributes
         * @request GET:/api/services/app/CarrierAccount/GetCarrierAttributes
         * @response `200` `(CarrierAccountsCarrierAttributeList)[]` Success
         */
        this.GetCarrierAttributes = (query, params = {}) => this.request({
            path: `/api/services/app/CarrierAccount/GetCarrierAttributes`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CarrierAccount
         * @name GetCarrierAccounts
         * @request GET:/api/services/app/CarrierAccount/GetCarrierAccounts
         * @response `200` `PagedResultCarrierAccountsCarrierAccountList` Success
         */
        this.GetCarrierAccounts = (query, params = {}) => this.request({
            path: `/api/services/app/CarrierAccount/GetCarrierAccounts`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CarrierAccount
         * @name GetCarrierAccountForEdit
         * @request GET:/api/services/app/CarrierAccount/GetCarrierAccountForEdit
         * @response `200` `CarrierAccountsGetCarrierAccountForEditOutput` Success
         */
        this.GetCarrierAccountForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/CarrierAccount/GetCarrierAccountForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CarrierAccount
         * @name CreateOrUpdateCarrierAccount
         * @request POST:/api/services/app/CarrierAccount/CreateOrUpdateCarrierAccount
         * @response `200` `CarrierAccountsGetCarrierAccountForEditOutput` Success
         */
        this.CreateOrUpdateCarrierAccount = (data, params = {}) => this.request({
            path: `/api/services/app/CarrierAccount/CreateOrUpdateCarrierAccount`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CarrierAccount
         * @name CreateOrUpdateMultiCarrierAccount
         * @request POST:/api/services/app/CarrierAccount/CreateOrUpdateMultiCarrierAccount
         * @response `200` `CarrierAccountsGetMultiCarrierAccountEditOutput` Success
         */
        this.CreateOrUpdateMultiCarrierAccount = (data, params = {}) => this.request({
            path: `/api/services/app/CarrierAccount/CreateOrUpdateMultiCarrierAccount`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CarrierAccount
         * @name DeleteCarrierAccount
         * @request DELETE:/api/services/app/CarrierAccount/DeleteCarrierAccount
         * @response `200` `void` Success
         */
        this.DeleteCarrierAccount = (query, params = {}) => this.request({
            path: `/api/services/app/CarrierAccount/DeleteCarrierAccount`,
            method: "DELETE",
            query: query,
            ...params,
        });
        /**
         * No description
         *
         * @tags CarrierAccount
         * @name GetCarrierAccountCustomerMappings
         * @request GET:/api/services/app/CarrierAccount/GetCarrierAccountCustomerMappings
         * @response `200` `PagedResultCarrierAccountsCarrierAccountMappingList` Success
         */
        this.GetCarrierAccountCustomerMappings = (query, params = {}) => this.request({
            path: `/api/services/app/CarrierAccount/GetCarrierAccountCustomerMappings`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CarrierAccount
         * @name MapCarrierAccountsToCustomer
         * @request POST:/api/services/app/CarrierAccount/MapCarrierAccountsToCustomer
         * @response `200` `void` Success
         */
        this.MapCarrierAccountsToCustomer = (data, params = {}) => this.request({
            path: `/api/services/app/CarrierAccount/MapCarrierAccountsToCustomer`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags CarrierAccount
         * @name UpdateCarrierAccountCustomerPreference
         * @request PUT:/api/services/app/CarrierAccount/UpdateCarrierAccountCustomerPreference
         * @response `200` `void` Success
         */
        this.UpdateCarrierAccountCustomerPreference = (data, params = {}) => this.request({
            path: `/api/services/app/CarrierAccount/UpdateCarrierAccountCustomerPreference`,
            method: "PUT",
            body: data,
            type: ContentType.Json,
            ...params,
        });
    }
}
