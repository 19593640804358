/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { HttpClient } from "./http-client.js";
export class History extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags History
         * @name Emails
         * @request GET:/api/communication/history/emails
         * @response `200` `CommunicationSharedEmailHistory` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.Emails = (query, params = {}) => this.request({
            path: `/api/communication/history/emails`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags History
         * @name EntityChanges
         * @request GET:/api/communication/history/entity-changes
         * @response `200` `(CommunicationSharedEntityHistory)[]` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.EntityChanges = (query, params = {}) => this.request({
            path: `/api/communication/history/entity-changes`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    }
}
