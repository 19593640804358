/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Fee extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Fee
         * @name GetFees
         * @request GET:/api/services/app/Fee/GetFees
         * @response `200` `PagedResultFinanceFeesFeeList` Success
         */
        this.GetFees = (query, params = {}) => this.request({
            path: `/api/services/app/Fee/GetFees`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Fee
         * @name GetFeeForEdit
         * @request GET:/api/services/app/Fee/GetFeeForEdit
         * @response `200` `FinanceFeesGetFeeForEditOutput` Success
         */
        this.GetFeeForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/Fee/GetFeeForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Fee
         * @name CreateOrUpdateFee
         * @request POST:/api/services/app/Fee/CreateOrUpdateFee
         * @response `200` `void` Success
         */
        this.CreateOrUpdateFee = (data, params = {}) => this.request({
            path: `/api/services/app/Fee/CreateOrUpdateFee`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Fee
         * @name AssignCustomerFee
         * @request POST:/api/services/app/Fee/AssignCustomerFee
         * @response `200` `void` Success
         */
        this.AssignCustomerFee = (data, params = {}) => this.request({
            path: `/api/services/app/Fee/AssignCustomerFee`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Fee
         * @name GetCustomerFeeForEdit
         * @request GET:/api/services/app/Fee/GetCustomerFeeForEdit
         * @response `200` `FinanceFeesGetCustomerFeeForEditOutput` Success
         */
        this.GetCustomerFeeForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/Fee/GetCustomerFeeForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Fee
         * @name AssignCustomerTypeFee
         * @request POST:/api/services/app/Fee/AssignCustomerTypeFee
         * @response `200` `void` Success
         */
        this.AssignCustomerTypeFee = (data, params = {}) => this.request({
            path: `/api/services/app/Fee/AssignCustomerTypeFee`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Fee
         * @name GetCustomerTypeFeeForEdit
         * @request GET:/api/services/app/Fee/GetCustomerTypeFeeForEdit
         * @response `200` `FinanceFeesGetCustomerTypeFeeForEditOutput` Success
         */
        this.GetCustomerTypeFeeForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/Fee/GetCustomerTypeFeeForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    }
}
