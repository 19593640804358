/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class UserSettings extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags UserSettings
         * @name GetAllUserSettings
         * @request GET:/api/services/app/UserSettings/GetAllUserSettings
         * @response `200` `UserSettingsUserSettingsOutput` Success
         */
        this.GetAllUserSettings = (query, params = {}) => this.request({
            path: `/api/services/app/UserSettings/GetAllUserSettings`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags UserSettings
         * @name CreateOrUpdateUserSettings
         * @request POST:/api/services/app/UserSettings/CreateOrUpdateUserSettings
         * @response `200` `void` Success
         */
        this.CreateOrUpdateUserSettings = (data, params = {}) => this.request({
            path: `/api/services/app/UserSettings/CreateOrUpdateUserSettings`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
    }
}
