/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Document extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Document
         * @name UploadDocument
         * @request POST:/api/services/app/Document/UploadDocument
         * @response `200` `void` Success
         */
        this.UploadDocument = (data, params = {}) => this.request({
            path: `/api/services/app/Document/UploadDocument`,
            method: "POST",
            body: data,
            type: ContentType.FormData,
            ...params,
        });
        /**
         * No description
         *
         * @tags Document
         * @name GetSharedDocuments
         * @request GET:/api/services/app/Document/GetSharedDocuments
         * @response `200` `(DocumentsDocument)[]` Success
         */
        this.GetSharedDocuments = (params = {}) => this.request({
            path: `/api/services/app/Document/GetSharedDocuments`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Document
         * @name GetDocuments
         * @request GET:/api/services/app/Document/GetDocuments
         * @response `200` `(DocumentsDocument)[]` Success
         */
        this.GetDocuments = (query, params = {}) => this.request({
            path: `/api/services/app/Document/GetDocuments`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Document
         * @name DeleteDocuments
         * @request DELETE:/api/services/app/Document/DeleteDocuments
         * @response `200` `void` Success
         */
        this.DeleteDocuments = (query, params = {}) => this.request({
            path: `/api/services/app/Document/DeleteDocuments`,
            method: "DELETE",
            query: query,
            ...params,
        });
    }
}
