/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { HttpClient } from "./http-client.js";
export class Suggestion extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Suggestion
         * @name SuggestUsers
         * @request GET:/api/communication/suggestion/suggest-users
         * @response `200` `(CommunicationSharedUser)[]` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.SuggestUsers = (query, params = {}) => this.request({
            path: `/api/communication/suggestion/suggest-users`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    }
}
