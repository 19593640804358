/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Role extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Role
         * @name GetRoles
         * @request GET:/api/services/app/Role/GetRoles
         * @response `200` `ListResultAuthorizationRolesRoleList` Success
         */
        this.GetRoles = (query, params = {}) => this.request({
            path: `/api/services/app/Role/GetRoles`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Role
         * @name GetCustomerRoles
         * @request GET:/api/services/app/Role/GetCustomerRoles
         * @response `200` `ListResultAuthorizationRolesRoleList` Success
         */
        this.GetCustomerRoles = (query, params = {}) => this.request({
            path: `/api/services/app/Role/GetCustomerRoles`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Role
         * @name GetRoleForEdit
         * @request GET:/api/services/app/Role/GetRoleForEdit
         * @response `200` `AuthorizationRolesGetRoleForEditOutput` Success
         */
        this.GetRoleForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/Role/GetRoleForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Role
         * @name CreateOrUpdateRole
         * @request POST:/api/services/app/Role/CreateOrUpdateRole
         * @response `200` `void` Success
         */
        this.CreateOrUpdateRole = (data, params = {}) => this.request({
            path: `/api/services/app/Role/CreateOrUpdateRole`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Role
         * @name DeleteRole
         * @request DELETE:/api/services/app/Role/DeleteRole
         * @response `200` `void` Success
         */
        this.DeleteRole = (query, params = {}) => this.request({
            path: `/api/services/app/Role/DeleteRole`,
            method: "DELETE",
            query: query,
            ...params,
        });
    }
}
