import * as React from "react"
import { isFunction } from "formik"
import { ApiContext } from "@shipeedo/ui-library/contexts/api"

import { QuotePayload } from "../types/quote"

export enum QuoteApiState {
  Idle,
  Loading,
  Aborted,
  Completed,
  TimedOut,
}

interface QuoteContextInterface {
  apiState: QuoteApiState
  setApiState: React.Dispatch<React.SetStateAction<QuoteApiState>>

  hasFocus: boolean
  setFocus: React.Dispatch<React.SetStateAction<boolean>>

  tab: string
  setTab: React.Dispatch<React.SetStateAction<string>>

  resourceToken: string
  getResourceToken: () => Promise<string>
  getQuotes: (
    token: string,
    abort: AbortController,
    attempt?: number
  ) => Promise<void>

  payload: Partial<QuotePayload>
  setPayload: (payload: QuotePayload) => void

  quotes: any
  clearQuotes: () => void

  gclid?: string
  config?: BookingWidgetConfig
}

export const QuoteContext = React.createContext<QuoteContextInterface>({
  apiState: QuoteApiState.Idle,
  setApiState: (apiState: QuoteApiState) => {},

  hasFocus: false,
  setFocus: (focus: boolean) => {},

  tab: "domestic",
  setTab: (tab: string) => {},

  resourceToken: "",
  getResourceToken: async () => "",
  getQuotes: async (token: string, abort: AbortController, attempt = 0) => {},

  payload: {},
  setPayload: (payload: any) => {},

  quotes: [],
  clearQuotes: () => {},
})

export default function QuoteProvider({ children, payload = {}, config }) {
  let { endpoint } = React.useContext(ApiContext)
  let [hasFocus, setFocus] = React.useState(false)
  let [tab, setTab] = React.useState(config?.initialTab || "domestic")
  let [data, setData] = React.useState<Partial<QuotePayload>>(payload)
  let [resourceToken, setResourceToken] = React.useState<string>()
  let [quotes, setQuotes] = React.useState<any>([])
  let [apiState, setApiState] = React.useState<QuoteApiState>(
    QuoteApiState.TimedOut
  )
  let [gclid, setGclid] = React.useState<string>()

  const getResourceToken = async (): Promise<string> => {
    setApiState(QuoteApiState.Loading)
    let req = await fetch(endpoint + `/api/services/app/quote/quote`, {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })

    try {
      let res = await req.json()
      let token = res.result.resourceToken
      setResourceToken(() => token)
      return token
    } catch (err) {
      // there probably wasn't enough data to get a quote
      setApiState(QuoteApiState.Idle)
    }
    // let token = "e8677c28-3124-4695-ba44-7b7167b818cd"
    // setResourceToken(() => token)
    // return token
  }

  const getQuotes = async (
    token: string,
    abort: AbortController,
    attempt = 0
  ): Promise<any> => {
    if (!token) {
      return
    }

    setApiState(QuoteApiState.Loading)

    try {
      let req = await fetch(
        endpoint + `/api/services/app/quote/getquotes?resourceToken=${token}`,
        {
          signal: abort.signal,
          method: "get",
          headers: {
            "content-type": "application/json",
          },
        }
      )

      if (req.status === 200) {
        let res = await req.json()
        setQuotes(() =>
          res.result.quotes.sort((a, b) =>
            a.customerCost.totalCost < b.customerCost.totalCost ? -1 : 1
          )
        )
        if (res.result.isCompleted) {
          setApiState(() => QuoteApiState.Completed)
          return
        } else if (attempt > 20) {
          setApiState(() => QuoteApiState.TimedOut)
          return
        }

        await new Promise((res) => setTimeout(res, 750))
        getQuotes(token, abort, attempt + 1)
      }
    } catch (err) {
      setApiState(() => QuoteApiState.Aborted)
    }
  }

  const clearQuotes = () => {
    setQuotes([])
  }

  let value = {
    config,
    apiState,
    setApiState,
    hasFocus,
    setFocus,
    tab,
    setTab,
    resourceToken,
    getResourceToken,
    getQuotes,
    payload: data,
    setPayload: setData,
    quotes,
    clearQuotes,
    gclid,
  }

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const gclid = urlParams.get("gclid")

    if (gclid) {
      setGclid(gclid)
    }
  }, [])

  return (
    <QuoteContext.Provider value={value}>
      {isFunction(children) ? children(value) : children}
    </QuoteContext.Provider>
  )
}
