/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class User extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags User
         * @name GetUsers
         * @request GET:/api/services/app/User/GetUsers
         * @response `200` `PagedResultAuthorizationUsersUserList` Success
         */
        this.GetUsers = (query, params = {}) => this.request({
            path: `/api/services/app/User/GetUsers`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags User
         * @name GetCustomerUsers
         * @request GET:/api/services/app/User/GetCustomerUsers
         * @response `200` `PagedResultAuthorizationUsersUserList` Success
         */
        this.GetCustomerUsers = (query, params = {}) => this.request({
            path: `/api/services/app/User/GetCustomerUsers`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags User
         * @name GetUsersToExcel
         * @request GET:/api/services/app/User/GetUsersToExcel
         * @response `200` `File` Success
         */
        this.GetUsersToExcel = (params = {}) => this.request({
            path: `/api/services/app/User/GetUsersToExcel`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags User
         * @name GetUserForEdit
         * @request GET:/api/services/app/User/GetUserForEdit
         * @response `200` `AuthorizationUsersGetUserForEditOutput` Success
         */
        this.GetUserForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/User/GetUserForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags User
         * @name GetUserPermissionsForEdit
         * @request GET:/api/services/app/User/GetUserPermissionsForEdit
         * @response `200` `AuthorizationUsersGetUserPermissionsForEditOutput` Success
         */
        this.GetUserPermissionsForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/User/GetUserPermissionsForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags User
         * @name UpdateUserPermissions
         * @request PUT:/api/services/app/User/UpdateUserPermissions
         * @response `200` `void` Success
         */
        this.UpdateUserPermissions = (data, params = {}) => this.request({
            path: `/api/services/app/User/UpdateUserPermissions`,
            method: "PUT",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags User
         * @name CreateOrUpdateUser
         * @request POST:/api/services/app/User/CreateOrUpdateUser
         * @response `200` `void` Success
         */
        this.CreateOrUpdateUser = (data, params = {}) => this.request({
            path: `/api/services/app/User/CreateOrUpdateUser`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags User
         * @name DeleteUser
         * @request DELETE:/api/services/app/User/DeleteUser
         * @response `200` `void` Success
         */
        this.DeleteUser = (query, params = {}) => this.request({
            path: `/api/services/app/User/DeleteUser`,
            method: "DELETE",
            query: query,
            ...params,
        });
        /**
         * No description
         *
         * @tags User
         * @name UnlockUser
         * @request POST:/api/services/app/User/UnlockUser
         * @response `200` `void` Success
         */
        this.UnlockUser = (data, params = {}) => this.request({
            path: `/api/services/app/User/UnlockUser`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags User
         * @name CreateApiKey
         * @request POST:/api/services/app/User/CreateAPIKey
         * @response `200` `EntitySystemGuid` Success
         */
        this.CreateAPIKey = (data, params = {}) => this.request({
            path: `/api/services/app/User/CreateAPIKey`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags User
         * @name DeleteApiKey
         * @request DELETE:/api/services/app/User/DeleteAPIKey
         * @response `200` `void` Success
         */
        this.DeleteAPIKey = (query, params = {}) => this.request({
            path: `/api/services/app/User/DeleteAPIKey`,
            method: "DELETE",
            query: query,
            ...params,
        });
    }
}
