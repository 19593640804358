export function validateQuoteAddress(address, prefix, values) {
  let errors = {}

  if (!address.type) {
    errors['type'] = `You must provide a ${prefix} building type`
  }

  if (!address.suburb) {
    errors['suburb'] = `You must provide a ${prefix} suburb`
  }

  if (!address.postCode) {
    errors['postCode'] = `You must provide a ${prefix} post code`
  }

  return Object.keys(errors).length > 0 ? errors : undefined;
}