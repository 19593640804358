"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var formik_1 = require("formik");
var react_1 = require("@headlessui/react");
var solid_1 = require("@heroicons/react/solid");
var options = [
    { name: 'Fragile', title: 'Fragile' },
    { name: 'Liquid', title: 'Contains liquids' },
    { name: 'Unusual', title: 'Unusual shape' }
];
function ItemOptions(props) {
    var form = (0, formik_1.useFormikContext)();
    var _a = React.useState([]), selectedOptions = _a[0], setSelectedOptions = _a[1];
    var handleSelectOption = function (options) {
        setSelectedOptions(options);
        var newOptions = options.reduce(function (prev, curr) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[curr] = true, _a)));
        }, {});
        form.setFieldValue("items.".concat(props.index, ".itemOptions"), newOptions);
    };
    return (React.createElement(formik_1.Field, { name: "items.".concat(props.index, ".itemOptions") }, function () { return (React.createElement(react_1.Listbox, { value: selectedOptions, onChange: handleSelectOption, multiple: true },
        React.createElement("div", { className: "group" },
            React.createElement(react_1.Listbox.Button, { className: "bg-primary hover:bg-blue-500 group-hover:bg-blue-500 text-white text-sm h-10 px-2 rounded flex flex-row space-x-1" },
                React.createElement(solid_1.AdjustmentsIcon, { className: "text-white w-5 h-5 my-auto" }),
                selectedOptions.length === 1 && React.createElement("span", { className: "my-auto" }, "1 selected"),
                selectedOptions.length > 1 && React.createElement("span", { className: "my-auto" },
                    selectedOptions.length,
                    " selected")),
            React.createElement(react_1.Listbox.Options, { className: "absolute z-10 max-w-xs w-40 border border-primary mt-1 bg-white rounded" }, options.map(function (option) { return (React.createElement(react_1.Listbox.Option, { key: option.name, value: option.name }, function (_a) {
                var active = _a.active, selected = _a.selected;
                return (React.createElement("li", { className: "flex px-3 py-1 overflow-hidden cursor-pointer ".concat(active ? 'bg-primary text-white' : 'text-black') },
                    React.createElement("span", { className: "text-sm ".concat(active ? 'text-white' : 'text-primary') }, option.title),
                    selected && React.createElement(solid_1.CheckIcon, { className: "w-4 h-4 ml-auto my-auto ".concat(active ? 'text-white' : 'text-primary') })));
            })); }))))); }));
}
exports.default = ItemOptions;
