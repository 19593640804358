/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Language extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Language
         * @name ExportLanguageTexts
         * @request GET:/api/services/app/Language/ExportLanguageTexts
         * @response `200` `void` Success
         */
        this.ExportLanguageTexts = (query, params = {}) => this.request({
            path: `/api/services/app/Language/ExportLanguageTexts`,
            method: "GET",
            query: query,
            ...params,
        });
        /**
         * No description
         *
         * @tags Language
         * @name ImportLanguageTexts
         * @request POST:/api/services/app/Language/ImportLanguageTexts
         * @response `200` `string` Success
         */
        this.ImportLanguageTexts = (query, params = {}) => this.request({
            path: `/api/services/app/Language/ImportLanguageTexts`,
            method: "POST",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Language
         * @name GetLanguages
         * @request GET:/api/services/app/Language/GetLanguages
         * @response `200` `LocalizationGetLanguagesOutput` Success
         */
        this.GetLanguages = (params = {}) => this.request({
            path: `/api/services/app/Language/GetLanguages`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Language
         * @name GetLanguageTexts
         * @request GET:/api/services/app/Language/GetLanguageTexts
         * @response `200` `PagedResultLocalizationLanguageTextList` Success
         */
        this.GetLanguageTexts = (query, params = {}) => this.request({
            path: `/api/services/app/Language/GetLanguageTexts`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Language
         * @name UpdateLanguageText
         * @request PUT:/api/services/app/Language/UpdateLanguageText
         * @response `200` `void` Success
         */
        this.UpdateLanguageText = (data, params = {}) => this.request({
            path: `/api/services/app/Language/UpdateLanguageText`,
            method: "PUT",
            body: data,
            type: ContentType.Json,
            ...params,
        });
    }
}
