/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class HostSettings extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags HostSettings
         * @name GetAllSettings
         * @request GET:/api/services/app/HostSettings/GetAllSettings
         * @response `200` `ConfigurationHostHostSettingsEdit` Success
         */
        this.GetAllSettings = (params = {}) => this.request({
            path: `/api/services/app/HostSettings/GetAllSettings`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags HostSettings
         * @name UpdateAllSettings
         * @request PUT:/api/services/app/HostSettings/UpdateAllSettings
         * @response `200` `void` Success
         */
        this.UpdateAllSettings = (data, params = {}) => this.request({
            path: `/api/services/app/HostSettings/UpdateAllSettings`,
            method: "PUT",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags HostSettings
         * @name SendTestEmail
         * @request POST:/api/services/app/HostSettings/SendTestEmail
         * @response `200` `void` Success
         */
        this.SendTestEmail = (data, params = {}) => this.request({
            path: `/api/services/app/HostSettings/SendTestEmail`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
    }
}
