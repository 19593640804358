import * as React from "react"
import { QuoteContext } from "../../contexts/quote-context"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

interface QuoteWidgetTabsProps {
  tabs?: string[]
}

export default function QuoteWidgetTabs(props: QuoteWidgetTabsProps) {
  let { tab, setTab } = React.useContext(QuoteContext)

  let tabs = React.useMemo(() => {
    let allTabs = [
      {
        name: "Domestic shipment",
        key: "domestic",
        current: tab === "domestic",
      },
      {
        name: "International shipment",
        key: "international",
        current: tab === "international",
      },
      {
        name: "Air / Sea Freight shipment",
        key: "air_sea",
        current: tab === "air_sea",
      },
      { name: "Track an item", key: "tracking", current: tab === "tracking" },
    ]

    if (props.tabs) {
      return allTabs.filter((tab) => props.tabs.includes(tab.key))
    }

    return allTabs
  }, [tab])

  return (
    <div>
      <nav
        className="relative z-0 rounded-t-lg shadow flex divide-x divide-gray-200"
        aria-label="Tabs"
      >
        {tabs.map((tab, tabIdx) => (
          <div
            key={tab.name}
            onClick={() => setTab(tab.key)}
            className={classNames(
              tab.current
                ? "text-gray-900"
                : "text-gray-500 hover:text-gray-700",
              tabIdx === 0 ? "rounded-tl-lg" : "",
              tabIdx === tabs.length - 1 ? "rounded-tr-lg" : "",
              "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
            )}
            aria-current={tab.current ? "page" : undefined}
          >
            <span>{tab.name}</span>
            <span
              aria-hidden="true"
              className={classNames(
                tab.current ? "bg-indigo-500" : "bg-transparent",
                "absolute inset-x-0 bottom-0 h-0.5"
              )}
            />
          </div>
        ))}
      </nav>
    </div>
  )
}
