"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var formik_1 = require("formik");
var React = __importStar(require("react"));
var ATTRIBUTES = [
    {
        "key": "ATL",
        "displayName": "Authority to leave",
        "group": "BookingAttributes",
        "subGroup": "DeliveryOptions",
        "dataType": "bool"
    },
    {
        "key": "Handload",
        "displayName": "Hand load",
        "group": "BookingAttributes",
        "subGroup": "PickupOptions",
        "dataType": "bool"
    },
    {
        "key": "HandUnload",
        "displayName": "Hand Unload",
        "group": "BookingAttributes",
        "subGroup": "DeliveryOptions",
        "dataType": "bool"
    },
    {
        "key": "TailLift",
        "displayName": "Tail Lift",
        "group": "BookingAttributes",
        "subGroup": null,
        "dataType": "bool"
    },
    {
        "key": "POBox",
        "displayName": "PO Box",
        "group": "BookingAttributes",
        "subGroup": "DeliveryOptions",
        "dataType": "bool"
    },
    {
        "key": "Fragile",
        "displayName": "Fragile",
        "group": "ItemAttributes",
        "subGroup": null,
        "dataType": "bool"
    },
    {
        "key": "Liquid",
        "displayName": "Liquid",
        "group": "ItemAttributes",
        "subGroup": null,
        "dataType": "bool"
    },
    {
        "key": "Unusual",
        "displayName": "Unusual",
        "group": "ItemAttributes",
        "subGroup": null,
        "dataType": "bool"
    }
];
function AddressOptions(props) {
    var form = (0, formik_1.useFormikContext)();
    var fieldMeta = form.getFieldMeta("".concat(props.property));
    var value = fieldMeta.value || {};
    var _a = React.useState(false), showMore = _a[0], setShowMore = _a[1];
    var handleOptionToggle = function (key) {
        var newValue = __assign({}, value);
        if (newValue[key]) {
            delete newValue[key];
        }
        else {
            newValue[key] = "true";
        }
        form.setFieldValue(props.property, newValue);
    };
    var options = React.useMemo(function () {
        return ATTRIBUTES
            .filter(function (attr) { return attr.group === "BookingAttributes" && (attr.subGroup === null || attr.subGroup.toLowerCase() === props.property.toLowerCase()); })
            .map(function (attr) { return ({
            key: attr.key,
            name: attr.displayName,
            value: value.hasOwnProperty(attr.key)
        }); });
    }, [props.property, value]);
    return (React.createElement(React.Fragment, null,
        !showMore && React.createElement("span", { onClick: function () { return setShowMore(true); }, className: "block md:hidden mt-2 text-primary text-sm hover:opacity-75 cursor-pointer" }, "More options"),
        React.createElement("div", { className: [
                "space-y-1",
                showMore ? "block" : "hidden md:block"
            ].join(" ") }, options.map(function (option) { return (React.createElement("div", { key: "".concat(props.property, "_").concat(option.key), onClick: function () { return handleOptionToggle(option.key); }, className: "flex items-center space-x-3" },
            React.createElement("span", { role: "checkbox", "aria-checked": "false", "aria-labelledby": "".concat(props.property, ".").concat(option.key), className: [
                    !option.value ? "bg-gray-200" : "bg-primary",
                    "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring"
                ].join(" ") },
                React.createElement("span", { "aria-hidden": "true", className: [
                        "inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200",
                        !option.value ? "translate-x-0" : "translate-x-5"
                    ].join(" ") })),
            React.createElement("span", { id: "".concat(props.property, ".").concat(option.key) },
                React.createElement("span", { className: "text-sm leading-5 text-gray-600" }, option.name)))); }))));
}
exports.default = AddressOptions;
