/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class CustomerCostCenter extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags CustomerCostCenter
         * @name GetCostCenters
         * @request GET:/api/services/app/CustomerCostCenter/GetCostCenters
         * @response `200` `PagedResultCustomerCostCentersCostCenterList` Success
         */
        this.GetCostCenters = (query, params = {}) => this.request({
            path: `/api/services/app/CustomerCostCenter/GetCostCenters`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CustomerCostCenter
         * @name GetCostCenterForEdit
         * @request GET:/api/services/app/CustomerCostCenter/GetCostCenterForEdit
         * @response `200` `CustomerCostCentersGetCostCenterForEditOutput` Success
         */
        this.GetCostCenterForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/CustomerCostCenter/GetCostCenterForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags CustomerCostCenter
         * @name CreateOrUpdateCostCenter
         * @request POST:/api/services/app/CustomerCostCenter/CreateOrUpdateCostCenter
         * @response `200` `void` Success
         */
        this.CreateOrUpdateCostCenter = (data, params = {}) => this.request({
            path: `/api/services/app/CustomerCostCenter/CreateOrUpdateCostCenter`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags CustomerCostCenter
         * @name DeleteCostCenter
         * @request DELETE:/api/services/app/CustomerCostCenter/DeleteCostCenter
         * @response `200` `void` Success
         */
        this.DeleteCostCenter = (query, params = {}) => this.request({
            path: `/api/services/app/CustomerCostCenter/DeleteCostCenter`,
            method: "DELETE",
            query: query,
            ...params,
        });
    }
}
