/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Booking extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Booking
         * @name GetBookings
         * @request GET:/api/services/app/Booking/GetBookings
         * @response `200` `PagedResultBookingsBookingList` Success
         */
        this.GetBookings = (query, params = {}) => this.request({
            path: `/api/services/app/Booking/GetBookings`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name GetBookingsSummary
         * @request GET:/api/services/app/Booking/GetBookingsSummary
         * @response `200` `BookingsBookingListSummary` Success
         */
        this.GetBookingsSummary = (query, params = {}) => this.request({
            path: `/api/services/app/Booking/GetBookingsSummary`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name GetBookingsForInvoice
         * @request GET:/api/services/app/Booking/GetBookingsForInvoice
         * @response `200` `PagedResultBookingsBookingList` Success
         */
        this.GetBookingsForInvoice = (query, params = {}) => this.request({
            path: `/api/services/app/Booking/GetBookingsForInvoice`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name GetBookingsForRecharge
         * @request GET:/api/services/app/Booking/GetBookingsForRecharge
         * @response `200` `PagedResultBookingsBookingList` Success
         */
        this.GetBookingsForRecharge = (query, params = {}) => this.request({
            path: `/api/services/app/Booking/GetBookingsForRecharge`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name GetBookingForEdit
         * @request GET:/api/services/app/Booking/GetBookingForEdit
         * @response `200` `BookingsGetBookingForEditOutput` Success
         */
        this.GetBookingForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/Booking/GetBookingForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name GetBookingStatusCount
         * @request GET:/api/services/app/Booking/GetBookingStatusCount
         * @response `200` `BookingsBookingStatusCount` Success
         */
        this.GetBookingStatusCount = (query, params = {}) => this.request({
            path: `/api/services/app/Booking/GetBookingStatusCount`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name CreateOrUpdateBatchBooking
         * @request POST:/api/services/app/Booking/CreateOrUpdateBatchBooking
         * @response `200` `BookingsBatchBookingOutput` Success
         */
        this.CreateOrUpdateBatchBooking = (data, params = {}) => this.request({
            path: `/api/services/app/Booking/CreateOrUpdateBatchBooking`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name DeleteBatchBooking
         * @request DELETE:/api/services/app/Booking/DeleteBatchBooking
         * @response `200` `BookingsDeleteBatchBookingOutput` Success
         */
        this.DeleteBatchBooking = (query, params = {}) => this.request({
            path: `/api/services/app/Booking/DeleteBatchBooking`,
            method: "DELETE",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name RunBulkOperation
         * @request POST:/api/services/app/Booking/RunBulkOperation
         * @response `200` `BookingsBulkBookingOperationOutput` Success
         */
        this.RunBulkOperation = (data, params = {}) => this.request({
            path: `/api/services/app/Booking/RunBulkOperation`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name AddBookingNote
         * @request POST:/api/services/app/Booking/AddBookingNote
         * @response `200` `BookingsGetBookingForEditOutput` Success
         */
        this.AddBookingNote = (data, params = {}) => this.request({
            path: `/api/services/app/Booking/AddBookingNote`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name CreateOrUpdateBooking
         * @request POST:/api/services/app/Booking/CreateOrUpdateBooking
         * @response `200` `BookingsGetBookingForEditOutput` Success
         */
        this.CreateOrUpdateBooking = (data, params = {}) => this.request({
            path: `/api/services/app/Booking/CreateOrUpdateBooking`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name CreateGuestBooking
         * @request POST:/api/services/app/Booking/CreateGuestBooking
         * @response `200` `BookingsGetBookingForEditOutput` Success
         */
        this.CreateGuestBooking = (data, params = {}) => this.request({
            path: `/api/services/app/Booking/CreateGuestBooking`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name CreateOrUpdateGuestBooking
         * @request POST:/api/services/app/Booking/CreateOrUpdateGuestBooking
         * @response `200` `BookingsGetBookingForEditOutput` Success
         */
        this.CreateOrUpdateGuestBooking = (data, params = {}) => this.request({
            path: `/api/services/app/Booking/CreateOrUpdateGuestBooking`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name GetTrackingDetails
         * @request GET:/api/services/app/Booking/GetTrackingDetails
         * @response `200` `BookingsGetTrackingDetailsOutput` Success
         */
        this.GetTrackingDetails = (query, params = {}) => this.request({
            path: `/api/services/app/Booking/GetTrackingDetails`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name DeleteBooking
         * @request DELETE:/api/services/app/Booking/DeleteBooking
         * @response `200` `void` Success
         */
        this.DeleteBooking = (query, params = {}) => this.request({
            path: `/api/services/app/Booking/DeleteBooking`,
            method: "DELETE",
            query: query,
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name GetDocuments
         * @request GET:/api/services/app/Booking/GetDocuments
         * @response `200` `BookingsDocumentDetailOutput` Success
         */
        this.GetDocuments = (query, params = {}) => this.request({
            path: `/api/services/app/Booking/GetDocuments`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name GetGuestDocuments
         * @request GET:/api/services/app/Booking/GetGuestDocuments
         * @response `200` `BookingsDocumentDetailOutput` Success
         */
        this.GetGuestDocuments = (query, params = {}) => this.request({
            path: `/api/services/app/Booking/GetGuestDocuments`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name GetGuestBooking
         * @request GET:/api/services/app/Booking/GetGuestBooking
         * @response `200` `BookingsGetBookingForEditOutput` Success
         */
        this.GetGuestBooking = (query, params = {}) => this.request({
            path: `/api/services/app/Booking/GetGuestBooking`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name UpdateBookingDetails
         * @request PUT:/api/services/app/Booking/UpdateBookingDetails
         * @response `200` `BookingsGetBookingForEditOutput` Success
         */
        this.UpdateBookingDetails = (data, params = {}) => this.request({
            path: `/api/services/app/Booking/UpdateBookingDetails`,
            method: "PUT",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name GetOperationLog
         * @request GET:/api/services/app/Booking/GetOperationLog
         * @response `200` `BookingsCarrierIntegrationLogOutput` Success
         */
        this.GetOperationLog = (query, params = {}) => this.request({
            path: `/api/services/app/Booking/GetOperationLog`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name GetOperationLogs
         * @request GET:/api/services/app/Booking/GetOperationLogs
         * @response `200` `PagedResultBookingsCarrierIntegrationLogList` Success
         */
        this.GetOperationLogs = (query, params = {}) => this.request({
            path: `/api/services/app/Booking/GetOperationLogs`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name CancelBooking
         * @request POST:/api/services/app/Booking/CancelBooking
         * @response `200` `void` Success
         */
        this.CancelBooking = (data, params = {}) => this.request({
            path: `/api/services/app/Booking/CancelBooking`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Booking
         * @name GetSharedBooking
         * @request GET:/api/services/app/Booking/GetSharedBooking
         * @response `200` `(BookingsGetBookingForEditOutput)[]` Success
         */
        this.GetSharedBooking = (params = {}) => this.request({
            path: `/api/services/app/Booking/GetSharedBooking`,
            method: "GET",
            format: "json",
            ...params,
        });
    }
}
