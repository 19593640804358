/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class TenantSettings extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags TenantSettings
         * @name GetAllSettings
         * @request GET:/api/services/app/TenantSettings/GetAllSettings
         * @response `200` `ConfigurationTenantsTenantSettingsEdit` Success
         */
        this.GetAllSettings = (params = {}) => this.request({
            path: `/api/services/app/TenantSettings/GetAllSettings`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags TenantSettings
         * @name GetBraintreeTokenizationKey
         * @request GET:/api/services/app/TenantSettings/GetBraintreeTokenizationKey
         * @response `200` `EntitySystemString` Success
         */
        this.GetBraintreeTokenizationKey = (params = {}) => this.request({
            path: `/api/services/app/TenantSettings/GetBraintreeTokenizationKey`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags TenantSettings
         * @name UpdateAllSettings
         * @request PUT:/api/services/app/TenantSettings/UpdateAllSettings
         * @response `200` `void` Success
         */
        this.UpdateAllSettings = (data, params = {}) => this.request({
            path: `/api/services/app/TenantSettings/UpdateAllSettings`,
            method: "PUT",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags TenantSettings
         * @name PatchAllSettings
         * @request PATCH:/api/services/app/TenantSettings/PatchAllSettings
         * @response `200` `void` Success
         */
        this.PatchAllSettings = (data, params = {}) => this.request({
            path: `/api/services/app/TenantSettings/PatchAllSettings`,
            method: "PATCH",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags TenantSettings
         * @name SendTestEmail
         * @request POST:/api/services/app/TenantSettings/SendTestEmail
         * @response `200` `void` Success
         */
        this.SendTestEmail = (data, params = {}) => this.request({
            path: `/api/services/app/TenantSettings/SendTestEmail`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
    }
}
