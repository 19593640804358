import * as React from "react"
import { Formik, Field, Form } from "formik"
import { useRouter } from "next/router"

import AddressSuburbSelector from "@shipeedo/ui-library/components/address/suburb-selector"
import AddressCountrySelector from "@shipeedo/ui-library/components/address/country-selector"

import { QuoteContext } from "../../contexts/quote-context"

export default function TrackingForm() {
  let router = useRouter()
  let { hasFocus } = React.useContext(QuoteContext)

  const handleValidate = (values) => {
    let errors = {}

    if (!values.consignmentId) {
      errors["consignmentId"] = "You must supply a consignment number"
    }

    if (!values.toAddress.suburb) {
      errors["toAddress"] = "You must supply a delivery post code"
    }

    return errors
  }

  const handleFormSubmit = (values, { setSubmitting }) => {

    // @ts-ignore
    window.location = `https://tracking.couriersandfreight.com.au/track/${values.consignmentId}/${values.toAddress.postCode}`
    return
  }

  return (
    <Formik
      validate={handleValidate}
      onSubmit={handleFormSubmit}
      initialValues={{
        toAddress: {
          countryCode: "AU",
        },
      }}
    >
      {({ values, ...formProps }) => (
        <Form>
          <div className="p-4">
            <div className="grid grid-cols-4 md:grid-cols-4 gap-4">
              <div className="col-span-4 md:col-span-2 flex flex-col">
                <label className="text-sm text-gray-500 mb-1">
                  Consignment number
                </label>
                <div className="relative">
                  <Field
                    name="consignmentId"
                    type="text"
                    className="text-sm px-3 py-2 border rounded w-full pl-10"
                  />
                  <svg
                    className="h-4 w-4 absolute top-0 bottom-0 ml-3 my-auto text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14"
                    />
                  </svg>
                </div>

                {/* @ts-ignore
                <ErrorMessage
                  name="consignmentId"
                  className="text-sm text-red-500 mt-1 block"
                  component="p"
                /> */}
              </div>
              <div className="col-span-4 md:col-span-2 flex flex-col h-10">
                <label className="text-sm text-gray-500 mb-1">
                  Delivery post code
                </label>
                <div className="relative flex flex-row gap-4">
                  <div className="h-10 flex-grow">
                    <AddressSuburbSelector property="toAddress" />
                  </div>
                  <div className="h-10">
                    <AddressCountrySelector property="toAddress" />
                  </div>
                </div>

                {/* @ts-ignore
                <ErrorMessage
                  name="consignmentId"
                  className="text-sm text-red-500 mt-1 block"
                  component="p"
                /> */}
              </div>

              <div
                className={`${hasFocus ? "block" : "hidden"} col-span-4 mt-4`}
              >
                <button
                  type="submit"
                  disabled={formProps.isSubmitting}
                  className="flex-1 px-12 py-3 block mx-auto text-white border-primary border bg-primary hover:bg-blue-cnf transition-all duration-100 rounded text-sm"
                >
                  Track shipment
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
