import * as React from "react"
import { useFormikContext } from "formik"

import { ApiContext } from "@shipeedo/ui-library/contexts/api"
import KeyboardList from "@shipeedo/ui-library/components/keyboard-list"

interface PortSelectorProps {
  property: string
  mode?: string
}

export default function AddressPortSelector(props: PortSelectorProps) {
  let { endpoint } = React.useContext(ApiContext)
  let form = useFormikContext()
  let [ports, setPorts] = React.useState<any>([])
  let [selectedValue, setSelectedValue] = React.useState("")
  let [open, setOpen] = React.useState(false)

  const loadAirports = async (countryCode: string) => {
    let req = await fetch(
      `${endpoint}/bookings-guest/api/air-ports?country=${countryCode}`
    )
    let res = await req.json()
    setPorts(
      res.map((item) => ({
        country: item.country,
        location: item.location,
        code: item.code,
        name: item.aiport,
      }))
    )
  }

  const loadSeaports = async (countryCode: string) => {
    let req = await fetch(
      `${endpoint}/bookings-guest/api/sea-ports?country=${countryCode}`
    )
    let res = await req.json()
    setPorts(
      (res || []).map((item) => ({
        country: item.country,
        location: item.city,
        code: item.unlocs[0],
        name: item.name,
      }))
    )
  }

  React.useEffect(() => {
    let countryCode = form.values[props.property].countryCode

    if (!countryCode) {
      return
    }

    if (props.mode === "air") {
      loadAirports(countryCode)
    } else {
      loadSeaports(countryCode)
    }
  }, [form.values[props.property].countryCode, props.property])

  const handleSetPort = (result) => {
    let currentValue = form.getFieldMeta<any>(props.property).value
    form.setFieldValue(props.property, {
      ...currentValue,
      port: result,
    })
    setOpen(false)
    setSelectedValue(`${result.name} (${result.code})`)
  }

  const handleFilterPort = (e: React.ChangeEvent<HTMLInputElement>) => {
    let {
      target: { value },
    } = e
    setSelectedValue(value)
  }

  return (
    <div className="relative">
      <input
        name={`${props.property}.port`}
        value={selectedValue}
        onFocus={() => setOpen(true)}
        onChange={handleFilterPort}
        type="text"
        className="border py-2 px-3 text-sm rounded w-full text-gray-900"
      />

      {open && (
        <KeyboardList className="absolute bg-white z-40 left-0 w-full -mt-1 border border-t-0 rounded-b leading-8 shadow divide-y max-h-64 overflow-y-scroll">
          {ports
            .filter((port) => {
              return (
                port.name.toLowerCase().indexOf(selectedValue.toLowerCase()) >
                  -1 ||
                port.code.toLowerCase().indexOf(selectedValue.toLowerCase()) >
                  -1
              )
            })
            .map((port, index) => (
              <li
                onClick={() => handleSetPort(port)}
                key={`${port.country}_${index}`}
              >
                <button
                  onClick={() => handleSetPort(port)}
                  className={`w-full text-left text-sm text-primary px-3 focus:bg-teal-100 focus:outline-none`}
                >
                  {port.name} ({port.code})
                  <span className="text-sm text-gray-500 float-right">
                    {port.location}
                  </span>
                </button>
              </li>
            ))}
        </KeyboardList>
      )}
    </div>
  )
}
