/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Invoice extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Invoice
         * @name GenerateInvoices
         * @request POST:/api/services/app/Invoice/GenerateInvoices
         * @response `200` `FinanceInvoicesInvoiceBatch` Success
         */
        this.GenerateInvoices = (data, params = {}) => this.request({
            path: `/api/services/app/Invoice/GenerateInvoices`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Invoice
         * @name GetInvoices
         * @request GET:/api/services/app/Invoice/GetInvoices
         * @response `200` `PagedResultFinanceInvoicesInvoiceListItem` Success
         */
        this.GetInvoices = (query, params = {}) => this.request({
            path: `/api/services/app/Invoice/GetInvoices`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Invoice
         * @name GetInvoiceForEdit
         * @request GET:/api/services/app/Invoice/GetInvoiceForEdit
         * @response `200` `FinanceInvoicesInvoiceOutput` Success
         */
        this.GetInvoiceForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/Invoice/GetInvoiceForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Invoice
         * @name AddInvoiceData
         * @request POST:/api/services/app/Invoice/AddInvoiceData
         * @response `200` `void` Success
         */
        this.AddInvoiceData = (data, params = {}) => this.request({
            path: `/api/services/app/Invoice/AddInvoiceData`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
        /**
         * No description
         *
         * @tags Invoice
         * @name GetInvoiceData
         * @request GET:/api/services/app/Invoice/GetInvoiceData
         * @response `200` `(FinanceInvoicesImportedInvoiceList)[]` Success
         */
        this.GetInvoiceData = (query, params = {}) => this.request({
            path: `/api/services/app/Invoice/GetInvoiceData`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Invoice
         * @name GetAdhocInvoiceForEdit
         * @request GET:/api/services/app/Invoice/GetAdhocInvoiceForEdit
         * @response `200` `FinanceInvoicesAdhocInvoiceOutput` Success
         */
        this.GetAdhocInvoiceForEdit = (query, params = {}) => this.request({
            path: `/api/services/app/Invoice/GetAdhocInvoiceForEdit`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Invoice
         * @name GenerateAdhocInvoice
         * @request POST:/api/services/app/Invoice/GenerateAdhocInvoice
         * @response `200` `FinanceInvoicesInvoiceOutput` Success
         */
        this.GenerateAdhocInvoice = (data, params = {}) => this.request({
            path: `/api/services/app/Invoice/GenerateAdhocInvoice`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Invoice
         * @name UpdateInvoiceNotes
         * @request PUT:/api/services/app/Invoice/UpdateInvoiceNotes
         * @response `200` `FinanceInvoicesInvoiceOutput` Success
         */
        this.UpdateInvoiceNotes = (data, params = {}) => this.request({
            path: `/api/services/app/Invoice/UpdateInvoiceNotes`,
            method: "PUT",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Invoice
         * @name GetOperationLogs
         * @request GET:/api/services/app/Invoice/GetOperationLogs
         * @response `200` `PagedResultFinanceInvoicesInvoiceOperationLogList` Success
         */
        this.GetOperationLogs = (query, params = {}) => this.request({
            path: `/api/services/app/Invoice/GetOperationLogs`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    }
}
