/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Order extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Order
         * @name GetOrders
         * @request GET:/api/services/app/Order/GetOrders
         * @response `200` `PagedResultOrderOrdersOrderList` Success
         */
        this.GetOrders = (query, params = {}) => this.request({
            path: `/api/services/app/Order/GetOrders`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Order
         * @name ImportOrder
         * @request POST:/api/services/app/Order/ImportOrder
         * @response `200` `OrderOrdersImportOrderOutput` Success
         */
        this.ImportOrder = (data, params = {}) => this.request({
            path: `/api/services/app/Order/ImportOrder`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Order
         * @name GetImportOrders
         * @request GET:/api/services/app/Order/GetImportOrders
         * @response `200` `OrderOrdersOrderResult` Success
         */
        this.GetImportOrders = (query, params = {}) => this.request({
            path: `/api/services/app/Order/GetImportOrders`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    }
}
