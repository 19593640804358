/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { HttpClient } from "./http-client.js";
export class Insurance extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Insurance
         * @name GetInsuranceQuotes
         * @request GET:/api/services/app/Insurance/GetInsuranceQuotes
         * @response `200` `BookingInsuranceInsuranceQuoteOutput` Success
         */
        this.GetInsuranceQuotes = (query, params = {}) => this.request({
            path: `/api/services/app/Insurance/GetInsuranceQuotes`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Insurance
         * @name GetGuestInsuranceQuotes
         * @request GET:/api/services/app/Insurance/GetGuestInsuranceQuotes
         * @response `200` `BookingInsuranceInsuranceQuoteOutput` Success
         */
        this.GetGuestInsuranceQuotes = (query, params = {}) => this.request({
            path: `/api/services/app/Insurance/GetGuestInsuranceQuotes`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    }
}
