"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var solid_1 = require("@heroicons/react/solid");
var React = __importStar(require("react"));
function ValidationErrors(props) {
    var errors = React.useMemo(function () {
        var result = [];
        if (!props.errors) {
            return result;
        }
        var _loop_1 = function (error) {
            var canClick = (error.hasOwnProperty('selector') && document.querySelector(error.selector)) || error.hasOwnProperty('element');
            var handleClick = function () {
                if (!canClick) {
                    return null;
                }
                var element = error.element;
                if (!element && error.selector) {
                    element = document.querySelector(error.selector);
                }
                element.focus();
            };
            var newError = __assign(__assign({}, error), { canClick: canClick, handleClick: handleClick });
            result.push(newError);
        };
        // iterate over the errors, checking the selector and setting canClick
        for (var _i = 0, _a = props.errors; _i < _a.length; _i++) {
            var error = _a[_i];
            _loop_1(error);
        }
        if (!props.fields) {
            return result;
        }
        else {
            return props.errors.filter(function (error) { return props.fields.includes(error.selector); });
        }
    }, [props.fields, props.errors]);
    if (errors.length === 0) {
        return null;
    }
    return (React.createElement("div", { className: "rounded-md bg-red-50 border border-red-500 p-4" },
        React.createElement("div", { className: "flex" },
            React.createElement("div", { className: "flex-shrink-0" },
                React.createElement(solid_1.XCircleIcon, { className: "w-5 h-5 text-red-400" })),
            React.createElement("div", { className: "ml-3" },
                React.createElement("h3", { className: "text-sm leading-5 font-medium text-red-800" }, props.title || "Errors"),
                React.createElement("div", { className: "mt-2 text-sm leading-5 text-red-700" },
                    React.createElement("ul", { className: "list-disc pl-5" }, errors.map(function (error, index) {
                        return (React.createElement("li", { key: "validationMessage__".concat(index), className: index !== 0 ? "mt-1" : "" }, error.canClick ? (React.createElement("button", { onClick: error.handleClick, className: "px-2 py-1.5 rounded-md text-sm text-left leading-5 font-medium hover:bg-red-100 focus:outline-none focus:bg-red-100 transition ease-in-out duration-150" }, error.message)) : error.message));
                    })))))));
}
exports.default = ValidationErrors;
