/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { HttpClient } from "./http-client.js";
export class EsDataMigrationService extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags ESDataMigrationService
         * @name GetEsUserData
         * @request GET:/api/services/app/ESDataMigrationService/GetESUserData
         * @response `200` `string` Success
         */
        this.GetESUserData = (params = {}) => this.request({
            path: `/api/services/app/ESDataMigrationService/GetESUserData`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags ESDataMigrationService
         * @name GetEsCustomerData
         * @request GET:/api/services/app/ESDataMigrationService/GetESCustomerData
         * @response `200` `string` Success
         */
        this.GetESCustomerData = (params = {}) => this.request({
            path: `/api/services/app/ESDataMigrationService/GetESCustomerData`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags ESDataMigrationService
         * @name GetEsBookingData
         * @request GET:/api/services/app/ESDataMigrationService/GetESBookingData
         * @response `200` `string` Success
         */
        this.GetESBookingData = (params = {}) => this.request({
            path: `/api/services/app/ESDataMigrationService/GetESBookingData`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags ESDataMigrationService
         * @name GetEsPaymentData
         * @request GET:/api/services/app/ESDataMigrationService/GetESPaymentData
         * @response `200` `string` Success
         */
        this.GetESPaymentData = (params = {}) => this.request({
            path: `/api/services/app/ESDataMigrationService/GetESPaymentData`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags ESDataMigrationService
         * @name GetEsRemittanceAdviceData
         * @request GET:/api/services/app/ESDataMigrationService/GetESRemittanceAdviceData
         * @response `200` `string` Success
         */
        this.GetESRemittanceAdviceData = (params = {}) => this.request({
            path: `/api/services/app/ESDataMigrationService/GetESRemittanceAdviceData`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags ESDataMigrationService
         * @name GetEsInvoiceData
         * @request GET:/api/services/app/ESDataMigrationService/GetESInvoiceData
         * @response `200` `string` Success
         */
        this.GetESInvoiceData = (params = {}) => this.request({
            path: `/api/services/app/ESDataMigrationService/GetESInvoiceData`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags ESDataMigrationService
         * @name GetEsCreditNotesData
         * @request GET:/api/services/app/ESDataMigrationService/GetESCreditNotesData
         * @response `200` `string` Success
         */
        this.GetESCreditNotesData = (params = {}) => this.request({
            path: `/api/services/app/ESDataMigrationService/GetESCreditNotesData`,
            method: "GET",
            format: "json",
            ...params,
        });
    }
}
