import { useFormikContext } from "formik"
import React, { ChangeEvent } from "react"

type AddressTypeProps = {
  property: string
  simple: boolean
}

type AddressType = 'Residential' | 'Factory' | 'Warehouse' | 'Retail'

export function AddressType(props: AddressTypeProps) {

  const form = useFormikContext()
  const field = form.getFieldMeta(`${props.property}.type`)
  const defaultValue = field ? field.value as string || "0" : "0"

  function handleChange(e: ChangeEvent<HTMLSelectElement>) {
    const type = e.target.value as AddressType
    if (type === 'Residential') {
      form.setFieldValue(`${props.property}.type`, "Residential")
      form.setFieldValue(`${props.property}.isResidential`, true)
    }
    else {
      form.setFieldValue(`${props.property}.type`, "Business")
      form.setFieldValue(`${props.property}.isResidential`, false)
    }
  }

  return (
    <select className="border p-2 text-sm text-gray-500 rounded" onChange={handleChange} defaultValue={defaultValue}>
      <option value={"0"} disabled>Please select</option>
      <option>Residential</option>
      <option>Factory</option>
      <option>Warehouse</option>
      <option>Retail</option>
    </select>
  )
}