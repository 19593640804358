/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { ContentType, HttpClient } from "./http-client.js";
export class Template extends HttpClient {
    constructor() {
        super(...arguments);
        /**
         * No description
         *
         * @tags Template
         * @name GetTemplateTypeFields
         * @request GET:/api/communication/template/get-template-type-fields
         * @response `200` `Record<string, (CommunicationDomainSharedFieldsTemplateFields)[]>` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.GetTemplateTypeFields = (params = {}) => this.request({
            path: `/api/communication/template/get-template-type-fields`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Template
         * @name GetTemplateTypes
         * @request GET:/api/communication/template/get-template-types
         * @response `200` `(CommunicationSharedTemplateType)[]` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.GetTemplateTypes = (params = {}) => this.request({
            path: `/api/communication/template/get-template-types`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Template
         * @name GetTemplateType
         * @request GET:/api/communication/template/get-template-type
         * @response `200` `CommunicationSharedTemplateType` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.GetTemplateType = (query, params = {}) => this.request({
            path: `/api/communication/template/get-template-type`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Template
         * @name GetTemplate
         * @request GET:/api/communication/template/get-template
         * @response `200` `CommunicationSharedTemplate` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.GetTemplate = (query, params = {}) => this.request({
            path: `/api/communication/template/get-template`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Template
         * @name GetTemplates
         * @request GET:/api/communication/template/get-templates
         * @response `200` `(CommunicationSharedTemplate)[]` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.GetTemplates = (params = {}) => this.request({
            path: `/api/communication/template/get-templates`,
            method: "GET",
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Template
         * @name GetTemplateResource
         * @request GET:/api/communication/template/get-template-resource
         * @response `200` `CommunicationSharedTemplateResource` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.GetTemplateResource = (query, params = {}) => this.request({
            path: `/api/communication/template/get-template-resource`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Template
         * @name GetTemplateResources
         * @request GET:/api/communication/template/get-template-resources
         * @response `200` `(CommunicationSharedTemplateResource)[]` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.GetTemplateResources = (query, params = {}) => this.request({
            path: `/api/communication/template/get-template-resources`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Template
         * @name CreateTemplate
         * @request POST:/api/communication/template/create-template
         * @response `200` `string` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.CreateTemplate = (data, params = {}) => this.request({
            path: `/api/communication/template/create-template`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Template
         * @name UpdateTemplate
         * @request PUT:/api/communication/template/update-template
         * @response `200` `string` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.UpdateTemplate = (data, params = {}) => this.request({
            path: `/api/communication/template/update-template`,
            method: "PUT",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
        /**
         * No description
         *
         * @tags Template
         * @name UploadTemplateImg
         * @request POST:/api/communication/template/upload-template-img
         * @response `200` `string` Success
         * @response `400` `ErrorResponse` Bad Request
         * @response `401` `ErrorResponse` Unauthorized
         * @response `403` `ErrorResponse` Forbidden
         * @response `404` `ErrorResponse` Not Found
         * @response `500` `ErrorResponse` Server Error
         * @response `501` `ErrorResponse` Server Error
         */
        this.UploadTemplateImg = (params = {}) => this.request({
            path: `/api/communication/template/upload-template-img`,
            method: "POST",
            format: "json",
            ...params,
        });
    }
}
